import React, { useMemo, useEffect } from "react";
import { extent } from "d3-array";
import { scaleLinear } from "d3-scale";
import moment from "moment";
import { AlbersUsa } from "@vx/geo";
import countiesJson from "../../../dataset/usa-geo.json";
import maxBy from "lodash/maxBy";
import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import TableLastYear from "./TableLastYear";
import { numberFormat } from "../../../utils";
import classNames from "classnames";

export const background = "#f9f7e8";

export default function IndicatorChoropletUSA({
  width = 700,
  height = 520,
  events = false,
  uom,
  uomLabel,
  direction,
  currentCounty,
  setCurrentCounty,
  rows,
  isMobile,
  geoLevel,
  isList
}) {
  const rowsWithDates = useMemo(() => {
    return rows.map(r => ({
      ...r,
      date: moment(r.time).toDate(),
      county: r.State || r.location
    }));
  }, [rows]);

  const maxDate = useMemo(() => {
    return maxBy(rowsWithDates, "date").date;
  }, [rowsWithDates]);

  const lastDateRows = useMemo(() => {
    return orderBy(
      rowsWithDates.filter(item => item.date >= maxDate && item.value),
      ["value", "location"],
      [direction === 0 ? "asc" : "desc", "desc"]
    );
  }, [direction, maxDate, rowsWithDates]);

  const lastDateValuesExtent = extent(lastDateRows, r => r.value);

  const topColor = "#00a9c5";
  const bottomColor = isMobile ? "#FFFFFF" : "#3a4a9f";

  const colorScale = scaleLinear()
    .range(direction === 0 ? [topColor, bottomColor] : [bottomColor, topColor])
    .domain(lastDateValuesExtent);

  const colorsByCounty = keyBy(
    lastDateRows.map(item => ({ color: colorScale(item.value), ...item })),
    "county"
  );

  const counties = countiesJson.features;

  const lastDatumToShow = useMemo(() => {
    if (uomLabel && uom) {
      return `${numberFormat(lastDateRows[0].value)} ${uom} ${uomLabel}`;
    } else if (uom) {
      return `${numberFormat(lastDateRows[0].value)} ${uom}`;
    } else {
      return `${numberFormat(lastDateRows[0].value)}`;
    }
  }, [uomLabel, lastDateRows, uom]);

  const valueForCounty = useMemo(() => {
    if (!currentCounty) {
      return null;
    }

    const rowDataCounty = lastDateRows.filter(
      d => d.location === currentCounty
    );
    if (rowDataCounty.length > 0) {
      if (uomLabel && uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom} ${uomLabel}`;
      } else if (uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom}`;
      } else {
        return `${numberFormat(rowDataCounty[0].value)}`;
      }
    } else {
      return `Not available`;
    }
  }, [currentCounty, lastDateRows, uom, uomLabel]);

  useEffect(() => {
    if (!isList && geoLevel === "US") {
      setCurrentCounty("Texas");
    }
  }, [geoLevel, isList, setCurrentCounty]);

  return (
    <div className="w-100">
      <div
        className={classNames("pt-2", "last-data", {
          "text-white": isMobile,
          "text-center": isMobile,
          "text-left": !isMobile,
          "text-pinkish": currentCounty
        })}
      >
        {currentCounty ? (
          <span>
            {currentCounty}:{" "}
            <span className="last-datum-value">{valueForCounty}</span>
          </span>
        ) : (
          <span>
            {lastDateRows[0].location && lastDateRows[0].location + ": "}
            <span className="last-datum-value">{lastDatumToShow}</span>
          </span>
        )}
      </div>
      <div
        style={{ height: isMobile && isList ? 450 : 470 }}
        className={classNames(
          "pl-3",
          "pt-2",
          "justify-content-around",
          "d-flex",
          {
            "bg-denim-blue": isMobile,
            "text-white": isMobile,
            "flex-column": isMobile,
            "align-items-center": isMobile && isList
          }
        )}
      >
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={`url(#geo_mercator_radial)`}
            rx={14}
          />
          <AlbersUsa
            data={counties}
            scale={isMobile ? 400 : 700}
            translate={[width / 2, isMobile ? height / 3 : height / 2]}
            fill={"red"}
            stroke={"white"}
          >
            {albers => (
              <g>
                {albers.features.map(({ feature, path }, i) => (
                  <path
                    key={`map-feature-${i}`}
                    className="pointer"
                    onClick={() => setCurrentCounty(feature.properties.name)}
                    d={path || ""}
                    fill={
                      colorsByCounty[feature.properties.name]
                        ? colorsByCounty[feature.properties.name].color
                        : "#CCC"
                    }
                    stroke={
                      currentCounty === feature.properties.name
                        ? "#f26852"
                        : "#FFF"
                    }
                    strokeWidth={
                      currentCounty === feature.properties.name ? 3 : 0.5
                    }
                  />
                ))}
              </g>
            )}
          </AlbersUsa>
        </svg>
        {!isList && (
          <TableLastYear
            data={lastDateRows}
            uom={uom}
            geoLevel={geoLevel}
            isMobile={isMobile}
            setCurrentCounty={setCurrentCounty}
            currentCounty={currentCounty}
          />
        )}
      </div>
    </div>
  );
}
