import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MobileContext } from "../providers/MobileProvider";
import { ConfigProvider } from "../providers/ConfigProvider";
import Home from "../pages/Home";
import IndicatorSwitch from "../pages/IndicatorSwitch";
import HomeMobile from "../pages/HomeMobile";
import NotFound from "../pages/NotFound";

export default function AppContainer() {
  const isMobile = useContext(MobileContext);

  return (
    <Router>
      <ConfigProvider>
        <Switch>
          <Route
            path="/"
            exact
            component={isMobile ? HomeMobile : Home}
          ></Route>
          <Route
            path="/topics/:topic"
            component={isMobile ? HomeMobile : Home}
          ></Route>
          <Route
            path="/goals/:goal"
            component={isMobile ? HomeMobile : Home}
          ></Route>
          <Route
            path="/goals/:goal"
            component={isMobile ? HomeMobile : Home}
          ></Route>
          <Route
            path="/indicator/:indicator"
            component={IndicatorSwitch}
          ></Route>
          <Route path="/" component={NotFound}></Route>
        </Switch>
      </ConfigProvider>
    </Router>
  );
}
