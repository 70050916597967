import React from "react";
import classnames from "classnames";

export default function LegendCharts({
  geoLevel,
  visualModel,
  isList = false
}) {
  return (
    <div className={classnames("mt-4")}>
      <div className="text-left">
        {geoLevel && (
          <img src={`/legenda-${geoLevel}.svg`} alt={`Legenda ${geoLevel}`} />
        )}
      </div>
      <div className="text-left mt-2">
        {visualModel === "Linechart" && (
          <img src={"/legenda-linechart.svg"} alt="Legenda Linechart" />
        )}
        {visualModel === "Barchart" && (
          <img src={"/legenda-barchart.svg"} alt="Legenda Barchart" />
        )}
        {visualModel === "Texas map" && (
          <img src={"/legenda-map.svg"} alt="Legenda map" />
        )}
        {visualModel === "Top and bottom" && geoLevel === "CNT" && (
          <img
            src={"/legenda-distribution-CNT.svg"}
            alt="Legenda CNT distribution"
          />
        )}
        {visualModel === "Top and bottom" && geoLevel === "US" && (
          <img
            src={"/legenda-distribution-US.svg"}
            alt="Legenda US distribution"
          />
        )}
      </div>
    </div>
  );
}
