import React, { Fragment } from "react";
import classNames from "classnames";
import "./TopicIndicatorsMenuLeft.scss";

export default function TopicIndicatorsMenuLeft({
  topic,
  indicatorsById,
  topicSelected,
  indicatorSelected,
  setIndicator
}) {
  return (
    <div
      className="TopicIndicatorsMenuLeft position-sticky"
      style={{ top: "183px" }}
    >
      {topicSelected &&
        topicSelected.subtopics &&
        topicSelected.subtopics.map((subtopic, key) => (
          <Fragment key={key}>
            <div>
              <div
                className={classNames("subtopic-name", "pb-2", {
                  "mt-4": key !== 0
                })}
              >
                {subtopic}
              </div>
            </div>
            <div className="d-flex flex-column">
              {topic[subtopic] &&
                topic[subtopic].map(indicator => (
                  <div
                    //onClick={() => setIndicator(indicator)}
                    //href={`#indicator${indicator}`}
                    key={indicator}
                    className={classNames(
                      "topic-title",
                      "pointer",
                      "pt-1",
                      "pb-1",
                      {
                        "topic-title-active": indicatorSelected === indicator
                      }
                    )}
                    onClick={() => {
                      const nodeIndicator = document.getElementById(
                        `indicator${indicator}`
                      );
                      const nodeFilters = document.getElementById(
                        "menu-filters"
                      );
                      window.scrollTo({
                        top:
                          nodeIndicator.offsetTop +
                          document.documentElement.clientHeight -
                          nodeFilters.clientHeight
                      });
                      //setIndicator(`#indicator${indicator}`);
                    }}
                  >
                    {indicatorsById[indicator] &&
                      indicatorsById[indicator]["title"]}
                  </div>
                ))}
            </div>
          </Fragment>
        ))}
    </div>
  );
}
