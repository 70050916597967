import React, { useMemo } from "react";
import classNames from "classnames";
import { extent } from "d3-array";
import uniqBy from "lodash/uniqBy";
import meanBy from "lodash/meanBy";
import maxBy from "lodash/maxBy";
import keyBy from "lodash/keyBy";
import differenceBy from "lodash/differenceBy";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import find from "lodash/find";
import moment from "moment";
import { scaleLinear } from "d3-scale";
import texasCounties from "../../../dataset/texas-counties.json";
import { numberFormat } from "../../../utils";
import TableLastYear from "./TableLastYear";

const gridsDispositions = texasCounties.features.map(feature => ({
  county: feature.properties.COUNTY,
  grid: feature.geometry.gridDisposition
}));

const numRows = maxBy(gridsDispositions, g => g.grid[0]).grid[0];
const numCols = maxBy(gridsDispositions, g => g.grid[1]).grid[1];

export default function IndicatorMapChart({
  isMobile,
  rows,
  isList,
  frequency,
  format,
  currentCounty,
  setCurrentCounty,
  boxSize = isMobile ? 14 : 20,
  maxWidth = isMobile ? 4 : 7,
  uom,
  geoLevel,
  uomLabel
}) {
  const rowsWithDates = useMemo(() => {
    return rows.map(r => ({
      ...r,
      date: moment(r.time).toDate(),
      county: r.State || r.location
    }));
  }, [rows]);

  const maxDate = useMemo(() => {
    return maxBy(rowsWithDates, "date").date;
  }, [rowsWithDates]);

  const lastDateRows = useMemo(() => {
    return orderBy(
      rowsWithDates.filter(item => item.date >= maxDate && item.value),
      ["value", "location"],
      ["desc", "asc"]
    );
  }, [maxDate, rowsWithDates]);

  const lastDateValuesExtent = extent(lastDateRows, r => r.value);

  const topColor = "#00a9c5";
  const bottomColor = isMobile ? "#FFFFFF" : "#3a4a9f";
  const lineColor = isMobile ? "#3a4a9f" : "#FFFFFF";

  const colorScale = scaleLinear()
    .range([bottomColor, topColor])
    .domain(lastDateValuesExtent);

  const colorsByCounty = keyBy(
    lastDateRows.map(item => ({ color: colorScale(item.value), ...item })),
    "county"
  );

  const counties = uniqBy(rowsWithDates, "county").map(r => r.county);

  const valuesExtent = extent(rowsWithDates, r => r.value);

  const valuesScale = scaleLinear()
    .range([0, boxSize])
    .domain([valuesExtent[1], valuesExtent[0]]);

  let dataByCounty = counties.map(county => {
    const countyRows = rowsWithDates.filter(r => r.county === county);
    const countyExtent = [
      countyRows[0].value,
      countyRows[countyRows.length - 1].value
    ];

    const countyMean = meanBy(countyRows, r => r.value);
    const y1 = valuesScale(countyExtent[0]);
    const y2 = valuesScale(countyExtent[1]);
    const rotation =
      countyExtent[1] && countyExtent[0]
        ? (countyExtent[1] / countyExtent[0]) * 25
        : undefined;

    const countyGeo = find(gridsDispositions, g => g.county === county);

    return {
      county,
      countyExtent,
      countyMean,
      y1,
      y2,
      yMid: (y2 + y1) / 2,
      countyGeo,
      rotation
    };
  });

  const meanValuesExtent = extent(dataByCounty, d => d.countyMean);
  const widthScale = scaleLinear()
    .range([0, maxWidth])
    .domain(meanValuesExtent);

  dataByCounty = dataByCounty.map(d => ({
    ...d,
    width: widthScale(d.countyMean),
    color: get(colorsByCounty, `[${d.county}].color`)
  }));

  const svgWidth = numCols * boxSize;
  const svgHeight = numRows * boxSize;

  const countiesNoData = differenceBy(
    gridsDispositions,
    dataByCounty,
    "county"
  );

  if (countiesNoData.length > 0) {
    countiesNoData.map(county =>
      dataByCounty.push({
        county: county.county,
        countyGeo: {
          county: county.county,
          grid: county.grid
        }
      })
    );
  }

  const currentCountyData = useMemo(() => {
    if (!currentCounty) {
      return null;
    }
    return dataByCounty.filter(d => d.county === currentCounty)[0];
  }, [currentCounty, dataByCounty]);

  const lastDatumToShow = useMemo(() => {
    if (uomLabel && uom) {
      return `${numberFormat(lastDateRows[0].value)} ${uom} ${uomLabel}`;
    } else if (uom) {
      return `${numberFormat(lastDateRows[0].value)} ${uom}`;
    } else {
      return `${numberFormat(lastDateRows[0].value)}`;
    }
  }, [uomLabel, lastDateRows, uom]);

  const valueForCounty = useMemo(() => {
    if (!currentCounty) {
      return null;
    }

    const rowDataCounty = lastDateRows.filter(
      d => d.location === currentCounty
    );
    if (rowDataCounty.length > 0) {
      if (uomLabel && uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom} ${uomLabel}`;
      } else if (uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom}`;
      } else {
        return `${numberFormat(rowDataCounty[0].value)}`;
      }
    } else {
      return `Not available`;
    }
  }, [currentCounty, lastDateRows, uom, uomLabel]);

  return (
    <div className="w-100">
      <div
        className={classNames("pt-2", "last-data", {
          "text-white": isMobile,
          "text-center": isMobile,
          "text-left": !isMobile,
          "text-pinkish": currentCounty
        })}
      >
        {currentCounty ? (
          <span>
            {currentCounty}:{" "}
            <span className="last-datum-value">{valueForCounty}</span>
          </span>
        ) : (
          <span>
            {lastDateRows[0].location && lastDateRows[0].location + ": "}
            <span className="last-datum-value">{lastDatumToShow}</span>
          </span>
        )}
      </div>
      <div
        style={{ height: isMobile && isList ? 450 : 470 }}
        className={classNames(
          "pl-3",
          "pt-2",
          "justify-content-around",
          "d-flex",
          {
            "bg-denim-blue": isMobile,
            "text-white": isMobile,
            "flex-column": isMobile,
            "align-items-center": isMobile && isList
          }
        )}
      >
        <svg width={svgWidth} height={svgHeight}>
          {dataByCounty &&
            dataByCounty
              .filter(x => x.countyGeo)
              .map(d => (
                <g
                  key={d.county}
                  transform={`translate(${d.countyGeo.grid[0] * boxSize}, ${d
                    .countyGeo.grid[1] * boxSize})`}
                >
                  <rect
                    fill={d.color ? d.color : "#abb6c1"}
                    strokeWidth={isMobile ? 1 : 2}
                    onClick={() => {
                      setCurrentCounty(d.county);
                    }}
                    stroke={
                      d.county === currentCounty
                        ? "#f26852"
                        : isMobile
                        ? "#3a4a9f"
                        : "#f3f3f3"
                    }
                    style={{ cursor: "pointer" }}
                    width={boxSize}
                    height={boxSize}
                  ></rect>
                  {d.rotation !== undefined && (
                    <line
                      x1={4}
                      x2={boxSize - 4}
                      y1={boxSize / 2}
                      y2={boxSize / 2}
                      transform={`rotate(${d.rotation} ${boxSize /
                        2} ${boxSize / 2})`}
                      strokeWidth={2}
                      stroke={lineColor}
                    ></line>
                  )}
                </g>
              ))}
          {currentCounty && currentCountyData && (
            <rect
              transform={
                currentCountyData.countyGeo
                  ? `translate(${currentCountyData.countyGeo.grid[0] *
                      boxSize}, ${currentCountyData.countyGeo.grid[1] *
                      boxSize})`
                  : null
              }
              strokeWidth={2}
              stroke={"#f26852"}
              fill={"transparent"}
              width={boxSize}
              height={boxSize}
            ></rect>
          )}
        </svg>
        {!isList && (
          <TableLastYear
            data={lastDateRows}
            uom={uom}
            geoLevel={geoLevel}
            isMobile={isMobile}
            setCurrentCounty={setCurrentCounty}
            currentCounty={currentCounty}
          />
        )}
      </div>
    </div>
  );
}
