import React from "react";
import { Waypoint } from "react-waypoint";
import "./TopicIndicatorsList.scss";
import IndicatorLinkBox from "../IndicatorLinkBox";
import IndicatorChartDetail from "../../../components/Desktop/IndicatorChartDetail";
import NoDataBlock from "../../../components/Desktop/IndicatorChartDetail/NoDataBlock";
import ExternalLinkBox from "../ExternalLinkBox";
import LegendCharts from "../../../components/Desktop/LegendCharts";
import { makeChartData } from "../../../utils";

export default function TopicIndicatorsList({
  topic,
  indicatorsById,
  topicSelected,
  setIndicator,
  indicatorSelected,
  clearIndicator,
  allSelectedIndicators,
  currentCounty,
  setCurrentCounty
}) {
  return (
    <div className="TopicIndicatorsListMobile">
      {topicSelected &&
        topicSelected.subtopics &&
        topicSelected.subtopics.map(
          (subtopic, key) =>
            topic[subtopic] &&
            topic[subtopic].map(indicator => {
              const chartData = makeChartData(indicatorsById[indicator]);
              return (
                <Waypoint
                  onEnter={() => setIndicator(indicator)}
                  onLeave={() => clearIndicator(indicator)}
                  scrollableAncestor={window}
                  bottomOffset={"20%"}
                  key={indicator}
                >
                  <div id={`indicator${indicator}`} className="pt-2">
                    {indicatorsById[indicator] && (
                      <div>
                        <div className="container">
                          <div className="topic-breadcrumb">
                            <span className="indicator-label">Indicator</span>
                            <span className="indicator-name ml-2">
                              {topicSelected.topic}
                            </span>
                          </div>
                          <div className="topic-title mt-2">
                            {indicatorsById[indicator]["title"]}
                          </div>
                          <div className="topic-subtitle mt-2">
                            {indicatorsById[indicator]["description"]}
                          </div>
                          <div className="topic-text mt-3">
                            {
                              indicatorsById[indicator][
                                "indicator-performance-description"
                              ]
                            }
                          </div>
                        </div>

                        <div
                          className="mt-4 mb-4"
                          style={{ backgroundColor: "#3a4a9f" }}
                        >
                          {chartData && chartData[0]["visualModel"] ? (
                            <IndicatorChartDetail
                              width={document.documentElement.clientWidth}
                              hidden={!allSelectedIndicators[indicator]}
                              isList={true}
                              currentCounty={currentCounty}
                              setCurrentCounty={setCurrentCounty}
                              chartsData={chartData}
                              isMobile
                              {...chartData[0]}
                            ></IndicatorChartDetail>
                          ) : (
                            <NoDataBlock isMobile={true} />
                          )}
                        </div>

                        <div className="container">
                          <IndicatorLinkBox
                            title={indicatorsById[indicator]["title"]}
                            idIndicator={indicatorsById[indicator]["id"]}
                          />
                          {indicatorsById[indicator] &&
                            indicatorsById[indicator]["external_link"] && (
                              <ExternalLinkBox
                                link={
                                  indicatorsById[indicator]["external_link"]
                                }
                              />
                            )}
                          <LegendCharts
                            geoLevel={indicatorsById[indicator]["geo_level"]}
                            visualModel={indicatorsById[indicator]["chart"]}
                          />
                        </div>
                      </div>
                    )}
                    <hr className="ml-3 mr-3 mt-4 mb-4" />
                  </div>
                </Waypoint>
              );
            })
        )}
    </div>
  );
}
