import React, { useEffect } from "react";
import classNames from "classnames";
import { numberFormat } from "../../../utils";

export default function TableLastYear({
  isMobile,
  data,
  setCurrentCounty,
  uom,
  currentCounty,
  geoLevel,
  tableLabel
}) {
  useEffect(() => {
    if (currentCounty && currentCounty !== "Texas") {
      const nodeCounty = document.getElementById(currentCounty);
      if (nodeCounty) {
        nodeCounty.scrollIntoView({ block: "center" });
      }
      window.scrollTo(0, 150);
    }
  }, [currentCounty]);

  return (
    data.length > 1 && (
      <div
        style={{
          height: isMobile ? 130 : 450,
          overflowY: "auto",
          maxWidth: 250,
          marginTop: isMobile ? undefined : 19
        }}
      >
        <table
          id="table-last-year"
          className={classNames(
            "table table-hover table-responsive table-counties-top-bottom",
            {
              "align-items-center": isMobile,
              "justify-content-center": isMobile,
              "d-flex": isMobile,
              "mt-3": isMobile
            }
          )}
        >
          <tbody>
            <tr>
              <td></td>
              <td
                className={classNames("text-left", {
                  "text-white": isMobile
                })}
              >
                {tableLabel
                  ? tableLabel
                  : geoLevel === "CNT"
                  ? "County"
                  : "State"}
              </td>
              <td
                className={classNames("text-center", {
                  "text-white": isMobile
                })}
              >
                Value
              </td>
            </tr>
            {data &&
              data
                .filter(d => d.value)
                .map((dataCounty, key) => (
                  <tr
                    className="pointer"
                    key={key}
                    id={`${dataCounty.location}`}
                    onClick={() => {
                      setCurrentCounty(dataCounty.location);
                    }}
                  >
                    <td
                      width={"15%"}
                      className={classNames({
                        "top-color": key === 0,
                        "pr-4": isMobile,
                        "text-pinkish": dataCounty.location === currentCounty,
                        "text-white":
                          isMobile && key !== 0 && data.length - 1 !== key,
                        "bottom-color": !isMobile && data.length - 1 === key,
                        "bottom-color-mobile":
                          isMobile && data.length - 1 === key
                      })}
                    >
                      {key + 1}
                    </td>
                    <td
                      width={"40%"}
                      className={classNames("text-left", {
                        "top-color": key === 0,
                        "pr-4": isMobile,
                        "text-pinkish": dataCounty.location === currentCounty,
                        "text-white":
                          isMobile && key !== 0 && data.length - 1 !== key,
                        "bottom-color": !isMobile && data.length - 1 === key,
                        "bottom-color-mobile":
                          isMobile && data.length - 1 === key
                      })}
                    >
                      {dataCounty.location}
                    </td>
                    <td
                      width={"45%"}
                      className={classNames("text-right pr-2 text-nowrap", {
                        "top-color": key === 0,
                        "text-pinkish": dataCounty.location === currentCounty,
                        "text-white":
                          isMobile && key !== 0 && data.length - 1 !== key,
                        "bottom-color": !isMobile && data.length - 1 === key,
                        "bottom-color-mobile":
                          isMobile && data.length - 1 === key
                      })}
                    >
                      {uom
                        ? `${numberFormat(dataCounty.value)} ${uom}`
                        : numberFormat(dataCounty.value)}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    )
  );
}
