import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ExternalLinkBox from "../../components/Desktop/ExternalLinkBox";
import GoalBox from "../../components/Desktop/GoalBox";
import IndicatorPerformanceTrend from "../../components/Desktop/IndicatorPerformanceTrend";
import IndicatorChartDetail from "../../components/Desktop/IndicatorChartDetail";
import CompareCounty from "../../components/Desktop/CompareCounty";
import NoDataBlock from "../../components/Desktop/IndicatorChartDetail/NoDataBlock";
import LegendCharts from "../../components/Desktop/LegendCharts";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "./Indicator.scss";

function trendLabel(color) {
  if (color === "Yellow") {
    return "Approaching Target";
  } else if (color === "Red") {
    return "Off Target";
  } else if (color === "Green") {
    return "On Target";
  }
}

export default function Indicator({
  match,
  history,
  indicator,
  currentChart,
  nextIndicator,
  prevIndicator,
  setCurrentChart,
  chartsData
}) {
  const [currentCounty, setCurrentCounty] = useState("null");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Indicator container-fluid pb-4">
      <div className="position-absolute" style={{ top: 10, right: 15 }}>
        <Link to={"/"}>
          <img width={64} src={"/logo-white.svg"} alt="Logo" />
        </Link>
      </div>
      <div className="row bg-denim-blue pt-2">
        <div className="col-md-9">
          {indicator && (
            <div>
              <div
                onClick={() => {
                  history.push(`/topics/${indicator["topic_number"]}`);
                  window.NO_LANDING = true;
                }}
                className="topic-title pointer"
                to={`/topics/${indicator["topic_number"]}`}
              >
                ← {indicator["topic"]}
              </div>
              <div
                className="indicator-title"
                style={{ color: indicator["test"] ? "red" : "" }}
              >
                {indicator["title"]}
              </div>
              <div className="indicator-subtitle pb-2">
                {indicator["subtitle"]}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 pt-3 text-center">
          {indicator && (
            <div className="mt-2 d-flex text-left row navigation-indicators">
              <div className="offset-md-4 col-md-4">
                {indicator["geo_level"] && indicator["geo_level"] === "CNT" && (
                  <CompareCounty
                    currentCounty={currentCounty}
                    setCurrentCounty={setCurrentCounty}
                    type={"CNT"}
                  />
                )}
                {indicator["geo_level"] && indicator["geo_level"] === "US" && (
                  <CompareCounty
                    currentCounty={currentCounty}
                    setCurrentCounty={setCurrentCounty}
                    type={"US"}
                  />
                )}
              </div>
            </div>
          )}
          {currentChart && currentChart["visualModel"] ? (
            <IndicatorChartDetail
              {...currentChart}
              chartsData={chartsData}
              setCurrentCounty={setCurrentCounty}
              currentCounty={currentCounty}
              setCurrentChart={setCurrentChart}
            />
          ) : (
            <NoDataBlock />
          )}
          <div className="d-flex justify-content-between">
            <div className="text-left">
              {prevIndicator && (
                <Link to={`/indicator/${prevIndicator["id"]}`}>
                  <div>
                    <strong className="mr-2">← Previous indicator</strong>
                    <br />
                    {prevIndicator["title"]}
                  </div>
                </Link>
              )}
            </div>
            <div className="text-right">
              {nextIndicator && (
                <Link to={`/indicator/${nextIndicator["id"]}`}>
                  <div>
                    <strong>Next indicator →</strong>
                    <br />
                    {nextIndicator["title"]}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3 border-left">
          {indicator && (
            <div>
              <div className="share-indicator mt-2">
                <span className="label">Share:</span>{" "}
                <span className="social pointer">
                  <FacebookShareButton url={window.location.href}>
                    Facebook
                  </FacebookShareButton>
                </span>{" "}
                <span className="social pointer">
                  <TwitterShareButton url={window.location.href}>
                    Twitter
                  </TwitterShareButton>
                </span>
              </div>
              <div className="trend-performance justify-content-between align-items-center d-flex pt-2 pb-2">
                <div className="border-performance-trend"></div>
                <IndicatorPerformanceTrend
                  performance={indicator["performance"]}
                  trend={indicator["trend"]}
                />
                <div className="border-performance-trend"></div>
              </div>
              <div className="indicator-performance">
                The current performance is{" "}
                <span
                  className={classNames({
                    red: indicator["performance"] === "Red",
                    gray: indicator["performance"] === "",
                    green: indicator["performance"] === "Green",
                    yellow: indicator["performance"] === "Yellow"
                  })}
                >
                  {indicator["performance"] === ""
                    ? "Not yet tracked"
                    : trendLabel(indicator["performance"])}
                </span>
              </div>
              {indicator["type_indicator"] !== 0 && (
                <div className="indicator-trend mt-4">
                  The current trend is{" "}
                  {indicator["trend"] ? (
                    <span
                      className={classNames({
                        red: indicator["performance"] === "Red",
                        green: indicator["performance"] === "Green",
                        gray: indicator["performance"] === "",
                        yellow: indicator["performance"] === "Yellow"
                      })}
                    >
                      {indicator["trend"]}
                    </span>
                  ) : (
                    <span>"Not yet tracked"</span>
                  )}
                </div>
              )}
              <div>
                <div className="indicator-description mt-4">
                  {indicator["description"]}
                </div>
              </div>
              {indicator["paragraph_goal"] && (
                <div className="indicator-paragraph mt-4 pt-2 pb-2 pl-3">
                  {indicator["paragraph_goal"]}
                </div>
              )}
              {indicator["frequency"] && (
                <div className="mt-4 frequency">
                  <div className="label">Data update frequency</div>
                  <div className="frequency-value mt-2">
                    {indicator["frequency"] === "A" ? "Annual" : "Monthly"}
                  </div>
                </div>
              )}
              {indicator["source"] && (
                <div className="mt-4 frequency">
                  <div className="label">Source</div>
                  <a
                    className="frequency-value mt-2"
                    rel="noopener noreferrer"
                    href={indicator["source_link"]}
                    target="_blank"
                  >
                    {indicator["source"]}
                  </a>
                </div>
              )}
              {indicator && indicator["goal_external_link"] && (
                <GoalBox
                  link={indicator["goal_external_link"]}
                  goal={indicator["goal"]}
                  number={indicator["goal_number"]}
                />
              )}
              {indicator && indicator["external_link"] && (
                <ExternalLinkBox link={indicator["external_link"]} />
              )}
              <LegendCharts
                geoLevel={indicator["geo_level"]}
                visualModel={indicator["chart"]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
