import { rj } from "react-rocketjump";
import request from "superagent";
import { DATA_URL } from "../../../constants";

export const IndicatorDataState = rj({
  effect: (id, graph) => {
    const fixedUrl = DATA_URL + id;

    return request.get(fixedUrl).then(res => {
      return res.body;
    });
  }
});
