import React from "react";
import classNames from "classnames";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";
import "./MenuTop.scss";

export default function MenuTop({
  topics,
  topicSelected,
  setTopic,
  history,
  setIndicator,
  setQuestion,
  goal,
  setGoal,
  setType
}) {
  const [test] = useRouterQueryParam("test");

  return (
    <div className="MenuTop m-auto pt-3 pb-3 position-sticky">
      <div className="d-flex flex-wrap justify-content-center">
        {topics.map(
          topic =>
            topic.number && (
              <div
                key={topic.number}
                className={classNames("menu-option", "pointer", "mr-2", {
                  active:
                    topicSelected &&
                    parseInt(topicSelected) === parseInt(topic.number)
                })}
                onClick={() => {
                  test
                    ? history.push(`/topics/${topic.number}?test=${test}`)
                    : history.push(`/topics/${topic.number}`);
                  window.NO_LANDING = true;
                }}
              >
                {topic.topic}
              </div>
            )
        )}
      </div>
    </div>
  );
}
