import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import {
  UncontrolledPopover,
  PopoverBody,
  Button,
  PopoverHeader
} from "reactstrap";
import range from "lodash/range";
import last from "lodash/last";
import head from "lodash/head";
import chunk from "lodash/chunk";

export default function FilterYear({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  minValueTime,
  maxValueTime,
  isMobile
}) {
  const [popoverStart, setPopoverStart] = useState(false);
  const [activeYearSlot, setYearSlots] = useState(0);

  const togglePopoverStart = () => setPopoverStart(!popoverStart);

  const [popoverEnd, setPopoverEnd] = useState(false);

  const togglePopoverEnd = () => setPopoverEnd(!popoverEnd);

  const yearStart =
    minValueTime &&
    moment(minValueTime)
      .utc()
      .format("YYYY");
  const yearEnd =
    maxValueTime &&
    moment(maxValueTime)
      .utc()
      .format("YYYY");
  const aDates = range(yearStart, yearEnd);

  aDates.push(parseInt(yearEnd));

  const splittedYears = chunk(aDates, 12);

  return (
    <div className="d-flex p-3">
      <div
        className={classNames("date-picker-container", "pointer", {
          "date-picker-container-active": fromDate,
          "date-picker-container-mobile": isMobile
        })}
      >
        <span id="popoverStart">
          FROM:{" "}
          {fromDate
            ? moment.utc(fromDate).format("YYYY")
            : minValueTime && moment.utc(minValueTime).format("YYYY")}
        </span>
        {fromDate && (
          <span className="pointer ml-2" onClick={() => setFromDate(null)}>
            {isMobile ? (
              <img src={"/close-bold-white.svg"} alt="Remove filter" />
            ) : (
              <img src={"/close-bold-black.svg"} alt="Remove filter" />
            )}
          </span>
        )}
      </div>
      <div
        className={classNames("date-picker-container", "pointer", "ml-2", {
          "date-picker-container-active": toDate,
          "date-picker-container-mobile": isMobile
        })}
      >
        <span id="popoverEnd">
          TO:{" "}
          {toDate
            ? moment.utc(toDate).format("YYYY")
            : maxValueTime && moment.utc(maxValueTime).format("YYYY")}
        </span>
        {toDate && (
          <span className="pointer ml-2" onClick={() => setToDate(null)}>
            {isMobile ? (
              <img src={"/close-bold-white.svg"} alt="Remove filter" />
            ) : (
              <img src={"/close-bold-black.svg"} alt="Remove filter" />
            )}
          </span>
        )}
      </div>
      <UncontrolledPopover
        trigger="legacy"
        placement="top"
        isOpen={popoverStart}
        target="popoverStart"
        toggle={togglePopoverStart}
      >
        <PopoverHeader
          className={classNames({
            "d-flex": splittedYears.length > 1,
            "justify-content-between": splittedYears.length > 1
          })}
        >
          {splittedYears[activeYearSlot - 1] ? (
            <div
              className="pointer"
              onClick={() => setYearSlots(activeYearSlot - 1)}
            >
              ←
            </div>
          ) : (
            <div></div>
          )}
          <div className="text-center">
            {head(splittedYears[activeYearSlot])} -{" "}
            {last(splittedYears[activeYearSlot])}
          </div>
          {splittedYears[activeYearSlot + 1] ? (
            <div
              className="pointer"
              onClick={() => setYearSlots(activeYearSlot + 1)}
            >
              →
            </div>
          ) : (
            <div></div>
          )}
        </PopoverHeader>
        <PopoverBody className="text-center">
          {minValueTime &&
            maxValueTime &&
            splittedYears[activeYearSlot] &&
            splittedYears[activeYearSlot].map(year => (
              <Button
                style={{ width: 70 }}
                key={year}
                onClick={() => {
                  setFromDate(moment.utc(year, "YYYY").startOf("year"));
                  togglePopoverStart();
                }}
                className={classNames("date-picker-container", "mr-2", "mb-2", {
                  "date-picker-container-active":
                    year === +moment.utc(fromDate).format("YYYY")
                })}
              >
                {year}
              </Button>
            ))}
        </PopoverBody>
      </UncontrolledPopover>
      <UncontrolledPopover
        trigger="legacy"
        placement="top"
        isOpen={popoverEnd}
        target="popoverEnd"
        toggle={togglePopoverEnd}
      >
        <PopoverHeader
          className={classNames({
            "d-flex": splittedYears.length > 1,
            "justify-content-between": splittedYears.length > 1
          })}
        >
          {splittedYears[activeYearSlot - 1] ? (
            <div
              className="pointer"
              onClick={() => setYearSlots(activeYearSlot - 1)}
            >
              ←
            </div>
          ) : (
            <div></div>
          )}
          <div className="text-center">
            {head(splittedYears[activeYearSlot])} -{" "}
            {last(splittedYears[activeYearSlot])}
          </div>
          {splittedYears[activeYearSlot + 1] ? (
            <div
              className="pointer"
              onClick={() => setYearSlots(activeYearSlot + 1)}
            >
              →
            </div>
          ) : (
            <div></div>
          )}
        </PopoverHeader>
        <PopoverBody className="text-center">
          {minValueTime &&
            maxValueTime &&
            splittedYears[activeYearSlot] &&
            splittedYears[activeYearSlot].map(year => (
              <Button
                style={{ width: 70 }}
                key={year}
                onClick={() => {
                  setToDate(
                    moment
                      .utc(year, "YYYY")
                      .endOf("year")
                      .toDate()
                  );
                  togglePopoverEnd();
                }}
                className={classNames("date-picker-container", "mr-2", "mb-2", {
                  "date-picker-container-active":
                    year === +moment.utc(toDate).format("YYYY")
                })}
              >
                {year}
              </Button>
            ))}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
}
