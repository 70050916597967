import React, { Fragment, useMemo, useEffect } from "react";
import classNames from "classnames";
import { Group } from "@vx/group";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import get from "lodash/get";
import last from "lodash/last";
import { extent } from "d3-array";
import { Text } from "@vx/text";
import moment from "moment";
import { Grid } from "@vx/grid";
import { Line } from "@vx/shape";
import { AxisLeft, AxisBottom, AxisTop } from "@vx/axis";
import { scaleLinear, scaleTime } from "@vx/scale";
import TableLastYear from "./TableLastYear";

import { STATES } from "../../../states";
import { numberFormat } from "../../../utils";

const white = "#FFFFFF";
const black = "#000000";
const denimBlue = "#3a4a9f";

export default function IndicatorTopBottomUs({
  isMobile,
  rows,
  isList = false,
  width = isList ? 650 : 700,
  height = 500,
  timeFormat,
  maxValueTime,
  currentCounty,
  minValueTime,
  setCurrentCounty,
  showedYears = isMobile ? 3 : 5,
  setToDate,
  setFromDate,
  direction,
  fromDate,
  uom,
  uomLabel,
  geoLevel,
  yAxis,
  xAxis
}) {
  const margin = {
    top: 50,
    bottom: isMobile ? 40 : 20,
    right: 20,
    left: isMobile ? 70 : 70
  };
  const marginLine = 15;

  const topColor = "#00a9c5";
  const bottomColor = isMobile ? "#FFCA08" : "#3a4a9f";
  const compareColor = "#f26852";

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom - 20;

  // const rowFiltered = useMemo(() => {
  //   return rows.filter(
  //     d =>
  //       moment(d.time).toDate() <= maxValueTime &&
  //       moment(d.time).toDate() >
  //         moment(maxValueTime).subtract(showedYears, "years")
  //   );
  // }, [maxValueTime, rows, showedYears]);

  const rowFiltered = rows;

  const byTime = groupBy(orderBy(rowFiltered, "location", "asc"), "time");

  let aggregateSerie = [];
  Object.keys(byTime).forEach(key => {
    const countyMax = maxBy(byTime[key], "value");
    const countyMin = minBy(byTime[key], "value");
    const countySelected = currentCounty
      ? byTime[key].filter(d => d.location === currentCounty)[0]
      : null;
    const time = moment(key).toDate();
    const data = byTime[key];
    //const state = byTime[key].location
    aggregateSerie.push({
      countyMax,
      states: orderBy(
        byTime[key].filter(
          d => d !== countyMax && d !== countyMin && d !== countySelected
        ),
        ["value", "location"],
        ["desc", "asc"]
      ),
      countySelected,
      countyMin,
      time,
      data
    });
  });
  aggregateSerie = orderBy(aggregateSerie, "time");

  const minTime = minBy(aggregateSerie, "time").time;
  const maxTime = maxBy(aggregateSerie, "time").time;
  const diffYears = moment(maxTime).diff(moment(minTime), "years", true) + 1;

  const numYears = diffYears;

  let years = [];
  Object.keys(byTime).forEach((key, i) => {
    years.push(
      moment(key)
        .utc()
        .format("YYYY")
    );
  });

  const lastYearData = orderBy(
    get(last(aggregateSerie), "data").filter(d => d.value),
    ["value", "location"],
    [direction === 0 ? "asc" : "desc"]
  );

  const larghezzaLine = chartWidth / numYears;

  const minMax = extent(rowFiltered, x => moment(x.time).toDate());

  const minDomain = minMax[0];
  const maxDomain = moment(minMax[1])
    .add(1, "year")
    .toDate();

  const minValue = useMemo(() => {
    return minBy(rowFiltered, "value") && minBy(rowFiltered, "value").value;
  }, [rowFiltered]);

  const maxValue = useMemo(() => {
    return maxBy(rowFiltered, "value") && maxBy(rowFiltered, "value").value;
  }, [rowFiltered]);

  const xScale = scaleTime({
    range: [0, chartWidth],
    domain: [minDomain, maxDomain]
  });
  //
  const yScale = scaleLinear({
    range: [chartHeight, 0],
    domain: direction === 0 ? [maxValue, minValue] : [minValue, maxValue]
    // domain: [0, max(fullSerie, y)]
  });

  const mediumValue =
    minValue && maxValue && minValue + (maxValue - minValue) / 2;

  const lastDatumToShow = useMemo(() => {
    if (uomLabel && uom) {
      return `${numberFormat(lastYearData[0].value)} ${uom} ${uomLabel}`;
    } else if (uom) {
      return `${numberFormat(lastYearData[0].value)} ${uom}`;
    } else {
      return `${numberFormat(lastYearData[0].value)}`;
    }
  }, [uomLabel, uom, lastYearData]);

  const valueForCounty = useMemo(() => {
    if (!currentCounty) {
      return null;
    }

    const rowDataCounty = lastYearData.filter(
      d => d.location === currentCounty
    );
    if (rowDataCounty.length > 0) {
      if (uomLabel && uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom} ${uomLabel}`;
      } else if (uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom}`;
      } else {
        return `${numberFormat(rowDataCounty[0].value)}`;
      }
    } else {
      return `Not available`;
    }
  }, [currentCounty, lastYearData, uom, uomLabel]);

  useEffect(() => {
    if (!isList && geoLevel === "US") {
      setCurrentCounty("Texas");
    }
  }, [geoLevel, isList, setCurrentCounty]);

  useEffect(() => {
    if (!isList && geoLevel === "US") {
      setCurrentCounty("Texas");
    }
  }, [geoLevel, isList, setCurrentCounty]);

  useEffect(() => {
    if (aggregateSerie.length > 5 && !fromDate && !isMobile) {
      setFromDate(moment(maxValueTime).subtract(4, "years"));
    }
    if (aggregateSerie.length > 3 && !fromDate && isMobile) {
      setFromDate(moment(maxValueTime).subtract(2, "years"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div
          className={classNames("pt-2", "last-data", {
            "text-white": isMobile,
            "text-center": isMobile,
            "text-left": !isMobile,
            "text-pinkish": currentCounty
          })}
        >
          {currentCounty ? (
            <span>
              {currentCounty}:{" "}
              <span className="last-datum-value">{valueForCounty}</span>
            </span>
          ) : (
            <span>
              {lastYearData[0].location && lastYearData[0].location + ": "}
              <span className="last-datum-value">{lastDatumToShow}</span>
            </span>
          )}
        </div>
        <div
          className={classNames({
            "d-flex": !isMobile,
            "justify-content-between": !isMobile
          })}
        >
          <svg
            width={width}
            height={height}
            style={{ backgroundColor: isMobile ? denimBlue : white }}
          >
            {xAxis && !isMobile && (
              <AxisTop
                top={margin.top}
                left={margin.left}
                scale={xScale}
                numTicks={0}
                label={xAxis}
                labelOffset={20}
                labelClassName={"y-axis"}
                tickStroke={"none"}
                stroke={isMobile ? white : "#b4b4b4"}
                strokeWidth={0.4}
              />
            )}
            <Grid
              top={margin.top}
              left={margin.left}
              xScale={xScale}
              yScale={yScale}
              stroke={isMobile ? white : "rgb(180,180,180,0.6)"}
              width={chartWidth}
              height={chartHeight}
              strokeWidth={0.4}
              numTicksRows={2}
              rowTickValues={[minValue, mediumValue, maxValue]}
              numTicksColumns={0}
            />
            <AxisLeft
              scale={yScale}
              top={margin.top}
              left={margin.left}
              numTicks={4}
              label={yAxis && !isMobile ? yAxis : ""}
              labelOffset={52}
              labelClassName={"y-axis"}
              tickFormat={tickValue => {
                return uom
                  ? `${numberFormat(tickValue)} ${uom}`
                  : numberFormat(tickValue);
              }}
              tickValues={[minValue, mediumValue, maxValue]}
              stroke={"none"}
              tickStroke={"none"}
              tickLabelProps={(value, index) => ({
                fill: isMobile ? white : black,
                fontSize: 12,
                textAnchor: "end",
                fontFamily: "Montserrat"
              })}
            />
            <AxisBottom
              top={height - margin.bottom - 20}
              left={margin.left}
              scale={xScale}
              tickStroke={"none"}
              stroke={isMobile ? white : "#b4b4b4"}
              strokeWidth={0.4}
              hideZero={false}
              numTicks={numYears}
              tickValues={years && years.map(x => moment(x, "YYYY"))}
              tickFormat={v => moment(v).format(timeFormat)}
              tickLabelProps={(value, index) => ({
                fill: isMobile ? white : black,
                dx: larghezzaLine / 2,
                fontSize: 12,
                dy: 15,
                textAnchor: "middle",
                fontFamily: "Montserrat"
              })}
            />
            <Group top={margin.top} left={margin.left} right={margin.right}>
              {aggregateSerie.map((serie, key) => (
                <Fragment key={key}>
                  {serie.states.map(
                    (state, keyState) =>
                      state.value &&
                      state.time && (
                        <g key={keyState}>
                          <Line
                            strokeWidth={1}
                            //onClick={() => setCurrentCounty(state.location)}
                            from={{
                              x: xScale(moment(state.time)) + marginLine,
                              y: yScale(state.value)
                            }}
                            to={{
                              x:
                                xScale(moment(state.time).add(1, "year")) -
                                marginLine,
                              y: yScale(state.value)
                            }}
                            style={{ zIndex: 100, cursor: "pointer" }}
                            stroke={"#b1c3d9"}
                            opacity={0.2}
                            id={state.location}
                          />
                          <Line
                            strokeWidth={3}
                            onClick={() => setCurrentCounty(state.location)}
                            from={{
                              x: xScale(moment(state.time)) + marginLine,
                              y: yScale(state.value)
                            }}
                            to={{
                              x:
                                xScale(moment(state.time).add(1, "year")) -
                                marginLine,
                              y: yScale(state.value)
                            }}
                            style={{ zIndex: 100, cursor: "pointer" }}
                            stroke={"transparent"}
                          />
                        </g>
                      )
                  )}
                  {serie.countyMax &&
                    serie.countyMax.location &&
                    serie.countyMax !== serie.countySelected && (
                      <>
                        <Line
                          strokeWidth={2}
                          onClick={() =>
                            setCurrentCounty(serie.countyMax.location)
                          }
                          from={{
                            x: xScale(serie.time) + marginLine,
                            y: yScale(serie.countyMax.value)
                          }}
                          style={{ cursor: "pointer" }}
                          to={{
                            x:
                              xScale(moment(serie.time).add(1, "year")) -
                              marginLine,
                            y: yScale(serie.countyMax.value)
                          }}
                          stroke={direction === 0 ? bottomColor : topColor}
                        />
                        <Text
                          x={xScale(serie.time)}
                          y={
                            direction === 0
                              ? yScale(serie.countyMax.value) + 15
                              : yScale(serie.countyMax.value) - 5
                          }
                          dx={
                            STATES[serie.countyMin.location]
                              ? larghezzaLine / 2 - marginLine / 2
                              : marginLine
                          }
                          stroke={direction === 0 ? bottomColor : topColor}
                          fontFamily={"Montserrat"}
                          style={{ fontSize: 12 }}
                        >
                          {STATES[serie.countyMax.location]
                            ? STATES[serie.countyMax.location]
                            : serie.countyMax.location && byTime.length !== 1
                            ? serie.countyMax.location
                                .substr(0, 10)
                                .toUpperCase()
                            : serie.countyMax.location}
                        </Text>
                      </>
                    )}
                  <Group>
                    {serie.countySelected && (
                      <>
                        <Line
                          strokeWidth={2}
                          from={{
                            x: xScale(serie.time) + marginLine,
                            y: yScale(serie.countySelected.value)
                          }}
                          to={{
                            x:
                              xScale(moment(serie.time).add(1, "year")) -
                              marginLine,
                            y: yScale(serie.countySelected.value)
                          }}
                          stroke={compareColor}
                        />
                        <Text
                          x={xScale(serie.time)}
                          y={yScale(serie.countySelected.value) - 5}
                          dx={
                            STATES[serie.countyMin.location]
                              ? larghezzaLine / 2 - marginLine / 2
                              : marginLine
                          }
                          stroke={compareColor}
                          fontFamily={"Montserrat"}
                          style={{ fontSize: 12 }}
                        >
                          {STATES[serie.countySelected.location]
                            ? STATES[serie.countySelected.location]
                            : serie.countySelected.location &&
                              byTime.length !== 1
                            ? serie.countySelected.location
                                .substr(0, 10)
                                .toUpperCase()
                            : serie.countySelected.location}
                        </Text>
                      </>
                    )}
                  </Group>
                  {serie.countyMin && serie.countyMin !== serie.countySelected && (
                    <Group>
                      <Line
                        onClick={() =>
                          setCurrentCounty(serie.countyMin.location)
                        }
                        strokeWidth={2}
                        style={{ cursor: "pointer" }}
                        from={{
                          x: xScale(serie.time) + marginLine,
                          y: yScale(serie.countyMin.value)
                        }}
                        to={{
                          x:
                            xScale(moment(serie.time).add(1, "year")) -
                            marginLine,
                          y: yScale(serie.countyMin.value)
                        }}
                        stroke={direction === 0 ? topColor : bottomColor}
                      />
                      <Text
                        x={xScale(serie.time)}
                        y={
                          direction === 0
                            ? yScale(serie.countyMin.value) - 5
                            : yScale(serie.countyMin.value) + 15
                        }
                        dx={
                          STATES[serie.countyMin.location]
                            ? larghezzaLine / 2 - marginLine / 2
                            : marginLine
                        }
                        stroke={
                          serie.countyMin.location === "Texas"
                            ? compareColor
                            : direction === 0
                            ? topColor
                            : bottomColor
                        }
                        fontFamily={"Montserrat"}
                        style={{ fontSize: 12 }}
                      >
                        {STATES[serie.countyMin.location]
                          ? STATES[serie.countyMin.location]
                          : serie.countyMin.location && byTime.length !== 1
                          ? serie.countyMin.location.substr(0, 10).toUpperCase()
                          : serie.countyMin.location}
                      </Text>
                    </Group>
                  )}
                </Fragment>
              ))}
            </Group>
          </svg>
          {!isList && (
            <TableLastYear
              data={lastYearData}
              geoLevel={geoLevel}
              uom={uom}
              isMobile={isMobile}
              setCurrentCounty={setCurrentCounty}
              currentCounty={currentCounty}
            />
          )}
        </div>
      </div>
    </>
  );
}
