import React from "react";
import Media from "react-media";

export const MobileContext = React.createContext([]);

export const MobileProvider = ({ children }) => {
  return (
    <Media query="(max-width: 800px)">
      {matches => (
        <MobileContext.Provider value={matches}>
          {children}
        </MobileContext.Provider>
      )}
    </Media>
  );
};
