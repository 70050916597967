import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "./MenuTop.scss";

export default function MenuTop({ topics, topicSelected, setIndicator }) {
  return (
    <div className="MenuTopMobile bg-white pb-3 pt-2 pl-2">
      <div className="d-flex flex-row">
        {topics.map(
          topic =>
            topic.number && (
              <Link
                key={topic.number}
                className={classNames(
                  "menu-option",
                  "pointer",
                  "mr-2",
                  "mt-2",
                  {
                    active:
                      topicSelected &&
                      parseInt(topicSelected) === parseInt(topic.number)
                  }
                )}
                to={`/topics/${topic.number}`}
              >
                {topic.topic}
              </Link>
            )
        )}
      </div>
    </div>
  );
}
