import React from "react";
import { rj, useRunRj } from "react-rocketjump";
import request from "superagent";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam";
import keyBy from "lodash/keyBy";
import { FILE_URL } from "../constants";

const getConfig = (test = "") => {
  return request.get(`${FILE_URL}?test=${test}`).then(res => {
    const rawData = res.body;

    const rawTopics = rawData.map(d => ({
      topic: d["topic"],
      number: d["topic_number"],
      subtopic: d["subtopic"]
    }));

    const subTopicsRaw = orderBy(
      rawTopics.map(d => ({
        topic: d.topic,
        subtopic: d.subtopic
      })),
      ["topic", "subtopic"]
    );
    const subtopicsByTopic = groupBy(subTopicsRaw, d => d.topic);

    const topics = orderBy(
      uniqBy(rawTopics, x => x["topic"]).map(d => ({
        topic: d.topic,
        number: d.number,
        subtopics: uniq(get(subtopicsByTopic, d.topic, []).map(d => d.subtopic))
      })),
      "topic"
    );

    const indicatorsById = keyBy(
      orderBy(rawData, ["topic", "subtitle", "title"]),
      item => item["id"]
    );

    const indicatorsOrdered = orderBy(rawData, [
      "topic",
      "subtopic",
      "chart_title",
      "title"
    ]);

    const indicatorsByTab = groupBy(rawData, "chart_group");

    const indicatorsByTopic = topics.reduce((indicatorsByTopic, topic) => {
      indicatorsByTopic[topic.number] = {};
      topic.subtopics.forEach(subtopic => {
        indicatorsByTopic[topic.number][subtopic] = orderBy(
          rawData.filter(item => item["subtopic"] === subtopic),
          "title",
          "asc"
        ).map(item => item["id"]);
      });
      return indicatorsByTopic;
    }, {});

    return {
      rawData,
      topics,
      indicatorsById,
      indicatorsByTopic,
      indicatorsByTab,
      indicatorsOrdered
    };
  });
};

const configState = rj({
  effect: getConfig
});

export const ConfigContext = React.createContext([]);

export const ConfigProvider = ({ children }) => {
  const [test] = useRouterQueryParam("test");
  const [state] = useRunRj(configState, [test], true, state => state);

  return (
    <ConfigContext.Provider value={state.data}>
      {children}
    </ConfigContext.Provider>
  );
};
