import React from "react";
import "./TopicIndicatorsList.scss";
import IndicatorLinkBox from "../IndicatorLinkBox";
import ExternalLinkBox from "../ExternalLinkBox";
import GoalBox from "../GoalBox";
import IndicatorChartDetail from "../../../components/Desktop/IndicatorChartDetail";
import LegendCharts from "../../../components/Desktop/LegendCharts";
import NoDataBlock from "../../../components/Desktop/IndicatorChartDetail/NoDataBlock";
import { Waypoint } from "react-waypoint";
import { makeChartData } from "../../../utils";

export default function TopicIndicatorsList({
  topic,
  indicatorsById,
  topicSelected,
  setIndicator,
  currentCounty,
  setCurrentCounty,
  clearIndicator,
  allSelectedIndicators,
  setCurrentState,
  currentState
}) {
  return (
    <div className="TopicIndicatorsList mt-2">
      {topicSelected &&
        topicSelected.subtopics &&
        topicSelected.subtopics.map(
          (subtopic, key) =>
            topic[subtopic] &&
            topic[subtopic].map(indicator => {
              const chartData = makeChartData(indicatorsById[indicator]);
              return (
                indicatorsById[indicator] &&
                indicatorsById[indicator]["title"] && (
                  <Waypoint
                    onEnter={() => setIndicator(indicator)}
                    onLeave={() => clearIndicator(indicator)}
                    //scrollableAncestor={window}
                    key={indicator}
                  >
                    <div className="row" id={`indicator${indicator}`}>
                      <div className="col-md-9">
                        <div>
                          <div className="topic-breadcrumb">
                            <span className="indicator-label">Indicator</span>
                            <span className="indicator-name ml-2">
                              {topicSelected.topic}
                            </span>
                          </div>
                          {indicatorsById[indicator] && (
                            <>
                              <div
                                className="topic-title mt-2"
                                style={{
                                  color: indicatorsById[indicator]["test"]
                                    ? "red"
                                    : ""
                                }}
                              >
                                {indicatorsById[indicator]["title"]}
                              </div>
                              <div className="topic-subtitle mt-2">
                                {indicatorsById[indicator]["subtitle"]}
                              </div>
                              <div className="topic-text mt-3">
                                {indicatorsById[indicator]["description"]}
                              </div>

                              <div className="mt-4 mb-4 text-center">
                                {chartData && chartData[0]["visualModel"] ? (
                                  <IndicatorChartDetail
                                    width={650}
                                    hidden={!allSelectedIndicators[indicator]}
                                    isList={true}
                                    setCurrentCounty={
                                      chartData[0]["geoLevel"] === "CNT"
                                        ? setCurrentCounty
                                        : chartData[0]["geoLevel"] === "US"
                                        ? setCurrentState
                                        : null
                                    }
                                    currentCounty={
                                      chartData[0]["geoLevel"] === "CNT"
                                        ? currentCounty
                                        : chartData[0]["geoLevel"] === "US"
                                        ? currentState
                                        : null
                                    }
                                    chartsData={chartData}
                                    {...chartData[0]}
                                  ></IndicatorChartDetail>
                                ) : (
                                  <NoDataBlock isMobile={false} />
                                )}
                              </div>
                            </>
                          )}
                          <hr className="mt-5" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        {indicatorsById[indicator] && (
                          <IndicatorLinkBox
                            title={indicatorsById[indicator]["title"]}
                            idIndicator={indicatorsById[indicator]["id"]}
                          />
                        )}
                        {indicatorsById[indicator] &&
                          indicatorsById[indicator]["goal_external_link"] && (
                            <GoalBox
                              link={
                                indicatorsById[indicator]["goal_external_link"]
                              }
                              number={indicatorsById[indicator]["goal_number"]}
                              goal={indicatorsById[indicator]["goal"]}
                            />
                          )}
                        {indicatorsById[indicator] &&
                          indicatorsById[indicator]["external_link"] && (
                            <ExternalLinkBox
                              link={indicatorsById[indicator]["external_link"]}
                            />
                          )}

                        <LegendCharts
                          visualModel={indicatorsById[indicator]["chart"]}
                          geoLevel={indicatorsById[indicator]["geo_level"]}
                          isList
                        />
                      </div>
                    </div>
                  </Waypoint>
                )
              );
            })
        )}
    </div>
  );
}
