import React, { Fragment, useMemo } from "react";
import { Bar, BarGroupHorizontal } from "@vx/shape";
import { Group } from "@vx/group";
import { GridColumns } from "@vx/grid";
import { AxisLeft, AxisBottom, AxisTop } from "@vx/axis";
import classNames from "classnames";
import { scaleBand, scaleLinear } from "@vx/scale";
import moment from "moment";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import maxBy from "lodash/maxBy";
import { Text } from "@vx/text";
import { numberFormat } from "../../../utils";

const margin = { top: 20, bottom: 50, right: 20, left: 70 };

const pinkish = "#f26852";
const white = "#FFFFFF";
const turqoise = "#00a9c5";
const black = "#000000";
const denimBlue = "#3a4a9f";

export default function IndicatorBarChart({
  width = 900,
  height = 500,
  aggregateSerie,
  countySerie,
  timeFormat,
  isMobile,
  uom,
  uomLabel,
  yAxis,
  xAxis
}) {
  // bounds

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const y = d => d.time;

  const countySerieByTime = keyBy(countySerie, "time");
  const barSerie = useMemo(() => {
    return aggregateSerie.map(item => {
      const obj = {
        time: item.time,
        texas: item.value
      };
      if (countySerie) {
        obj.county = get(countySerieByTime, item.time, {}).value;
      }
      return obj;
    });
  }, [aggregateSerie, countySerie, countySerieByTime]);

  const hasCountySerie = countySerie && countySerie.length > 0;

  const barKeys = hasCountySerie ? ["texas", "county"] : ["texas"];

  const lastDatumToShow = useMemo(() => {
    const lastYearData = maxBy(aggregateSerie, "time");
    if (uomLabel && uom) {
      return `${numberFormat(lastYearData.value)} ${uom} ${uomLabel}`;
    } else if (uom) {
      return `${numberFormat(lastYearData.value)} ${uom}`;
    } else {
      return numberFormat(lastYearData.value);
    }
  }, [aggregateSerie, uom, uomLabel]);

  // scales
  const xScale = scaleLinear({
    rangeRound: [0, chartWidth],
    domain: [
      0,
      Math.max(
        ...barSerie.map(x =>
          hasCountySerie ? Math.max(x.texas, x.county) : x.texas
        )
      )
    ]
  });

  const y0Scale = scaleBand({
    rangeRound: [0, chartHeight],
    domain: barSerie.map(y),
    padding: 0.4
  });

  const y1Scale = scaleBand({
    rangeRound: [0, y0Scale.bandwidth()],
    domain: barKeys,
    padding: 0.1
  });

  return (
    <div>
      <div
        className={classNames("pt-2", "last-data", {
          "text-white": isMobile,
          "text-center": isMobile,
          "text-left": !isMobile
        })}
      >
        {lastDatumToShow}
      </div>
      <svg
        width={width}
        height={height}
        style={{ backgroundColor: isMobile ? denimBlue : white }}
      >
        <GridColumns
          top={margin.top}
          left={margin.left}
          scale={xScale}
          stroke={isMobile ? white : "#ccc"}
          width={chartWidth}
          height={chartHeight}
          opacity={0.4}
        />
        <AxisLeft
          scale={y0Scale}
          top={margin.top}
          left={margin.left}
          stroke={"none"}
          tickStroke={"none"}
          label={yAxis && !isMobile ? yAxis : ""}
          labelOffset={52}
          labelClassName={"y-axis"}
          tickFormat={v =>
            moment(v)
              .utc()
              .format(timeFormat)
          }
          tickLabelProps={(value, index) => ({
            fill: isMobile ? white : black,
            fontSize: 12,
            fontWeight: 500,
            textAnchor: "end",
            fontFamily: "Montserrat"
          })}
        />
        <AxisBottom
          top={height - margin.bottom}
          left={margin.left}
          scale={xScale}
          stroke="none"
          numTicks={isMobile ? 7 : null}
          tickFormat={tickValue => {
            return uom
              ? `${numberFormat(tickValue)} ${uom}`
              : numberFormat(tickValue);
          }}
          tickStroke={"none"}
          tickLabelProps={(value, index) => ({
            fill: isMobile ? white : black,
            fontSize: 12,
            textAnchor: "middle",
            fontFamily: "Montserrat"
          })}
        />
        {xAxis && !isMobile && (
          <AxisTop
            top={margin.top}
            left={margin.left}
            scale={xScale}
            numTicks={0}
            label={xAxis}
            labelOffset={20}
            labelClassName={"y-axis"}
            tickStroke={"none"}
          />
        )}

        <BarGroupHorizontal
          data={barSerie}
          keys={barKeys}
          width={chartWidth}
          y0={y}
          y0Scale={y0Scale}
          y1Scale={y1Scale}
          xScale={xScale}
          color={() => "red"}
        >
          {barGroups => {
            return barGroups.map(barGroup => {
              return (
                <Group
                  key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                  top={barGroup.y0 + margin.top}
                  left={margin.left}
                >
                  {barGroup.bars.map(bar => {
                    return (
                      <Fragment
                        key={`${barGroup.index}-${bar.index}-${bar.key}`}
                      >
                        <Bar
                          x={bar.x}
                          y={bar.y}
                          width={bar.width || 0}
                          height={bar.height}
                          fill={bar.key === "texas" ? pinkish : turqoise}
                          // onClick={event => {
                          //   alert(
                          //     `${bar.key} (${bar.value}) - ${JSON.stringify(bar)}`
                          //   );
                          // }}
                        />
                        <Text
                          x={bar.x + 5}
                          y={bar.y + bar.height / 2 + 4}
                          stroke={"#FFFFFF"}
                          fontFamily={"Montserrat"}
                          style={{ fontSize: 12, fontWeight: 400 }}
                        >
                          {bar.value.toLocaleString("en", {
                            maximumFractionDigits: 2
                          })}
                        </Text>
                      </Fragment>
                    );
                  })}
                </Group>
              );
            });
          }}
        </BarGroupHorizontal>
      </svg>
    </div>
  );
}
