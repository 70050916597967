import React, { useState } from "react";
import "./SelectorDetail.scss";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

export default function SelectorDetail({
  topic,
  indicators,
  indicatorSelected,
  setIndicator,
  indicatorsById
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const dropdownActive =
    indicatorsById[indicatorSelected] &&
    indicatorsById[indicatorSelected]["title"];

  return (
    <div className="SelectorDetail">
      <Dropdown isOpen={dropdownOpen} direction={"down"} toggle={toggle}>
        <DropdownToggle
          caret={false}
          className="w-100 dropdown-toggle pt-2 pb-2 pl-4 pr-4 text-left border-radius-0 d-flex justify-content-between align-items-center"
        >
          {dropdownActive}
          <img
            src={dropdownOpen ? "/icon-arrow-up.png" : "/icon-arrow-down.png"}
            alt="arrow"
          />
        </DropdownToggle>
        <DropdownMenu className="m-0 pt-0">
          {indicators &&
            indicators.map(indicator => (
              <DropdownItem key={indicator["id"]}>
                <div
                  //href={`#indicator${indicator["id"]}`}
                  onClick={() => {
                    const nodeIndicator = document.getElementById(
                      `indicator${indicator["id"]}`
                    );
                    const nodeFilters = document.getElementById("blockFilters");
                    window.scrollTo({
                      top:
                        nodeIndicator &&
                        nodeIndicator.offsetTop +
                          document.documentElement.clientHeight -
                          nodeFilters.clientHeight
                    });
                  }}
                  className="w-100 m-0"
                >
                  {indicator["title"]}
                </div>
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
