import React from "react";

export default function IndicatorPerformanceTrend({ performance, trend }) {
  let colorPerformanceTrend = "#FFF";
  if (performance === "Yellow") {
    colorPerformanceTrend = "#FECD61";
  } else if (performance === "Red") {
    colorPerformanceTrend = "#BE4854";
  } else if (performance === "Green") {
    colorPerformanceTrend = "#20B138";
  } else if (performance === "") {
    colorPerformanceTrend = "#D4D7D9";
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      {trend === "Flat" && (
        <path
          fill={colorPerformanceTrend}
          d="M20,1.3L1.3,20L20,38.7L38.7,20L20,1.3z M37.3,20L20.5,36.8V3.2L37.3,20z M19.5,3.2v33.6L2.7,20L19.5,3.2z
    		 M20,37.3L20,37.3L20,37.3L20,37.3z"
        />
      )}
      {trend === "" && (
        <polygon
          fill={colorPerformanceTrend}
          points="38.2,20.2 38.2,20.2 20.2,2.2 2.2,20.2 2.2,20.2 20.2,38.2 "
        />
      )}
      {trend === "Improving" && (
        <path
          fill={colorPerformanceTrend}
          d="M20.2,38.9l18.7-18.7L20.2,1.5L1.5,20.2L20.2,38.9z M2.9,20.2h34.6L20.2,37.5L2.9,20.2z"
        />
      )}
      {trend === "Worsening" && (
        <path
          className="st0"
          fill={colorPerformanceTrend}
          d="M20.2,1.5L1.5,20.2l18.7,18.7l18.7-18.7L20.2,1.5z M37.5,20.2H2.9L20.2,2.9L37.5,20.2z"
        />
      )}
      {trend === "Mixed" && (
        <g>
          <path
            fill={colorPerformanceTrend}
            className="st0"
            d="M20,1.3L1.3,20L20,38.7L38.7,20L20,1.3z M37.3,20L20.5,36.8V3.2L37.3,20z M19.5,3.2v33.6L2.7,20L19.5,3.2z
           M20,37.3L20,37.3L20,37.3L20,37.3z"
          />
          <g>
            <polygon
              fill={colorPerformanceTrend}
              className="st1"
              points="18.5,11.4 9.9,20 18.5,28.6 		"
            />
            <polygon
              fill={colorPerformanceTrend}
              className="st1"
              points="21.5,11.4 21.5,28.6 30.1,20 		"
            />
          </g>
        </g>
      )}
    </svg>
  );
}
