import React, { useEffect, useMemo, useState } from "react";
import { Group } from "@vx/group";
import { Grid } from "@vx/grid";
import { LinePath } from "@vx/shape";
import { AxisLeft, AxisBottom, AxisTop } from "@vx/axis";
import { GlyphDot } from "@vx/glyph";
import { Text } from "@vx/text";
import { scaleLinear, scaleTime } from "@vx/scale";
import { extent } from "d3-array";
import moment from "moment";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import classNames from "classnames";
import { numberFormat } from "../../../utils";
import TableLastYear from "./TableLastYear";

const pinkish = "#f26852";
const white = "#FFFFFF";
const black = "#000000";
const denimBlue = "#3a4a9f";
const lightBlue = "rgba(177, 195, 217,0.2)";

export default function IndicatorLineChart({
  geoLevel,
  isList,
  height = 500,
  frequency,
  timeFormat,
  direction,
  isMobile,
  uom,
  uomLabel,
  rows,
  minValueTime,
  maxValueTime,
  currentCounty,
  setCurrentCounty,
  yAxis,
  xAxis,
  tableLabel
}) {
  // accessors
  const x = d => d.time;
  const y = d => d.value;

  const rowsWithTime = rows.map(row => ({
    time: moment(row.time).toDate(),
    value: row.value,
    location: row.location
  }));

  const dataByLocation = groupBy(rowsWithTime, "location");

  const locations = Object.keys(dataByLocation);

  const minValue =
    minBy(rowsWithTime, "value") && minBy(rowsWithTime, "value").value;
  const maxValue =
    maxBy(rowsWithTime, "value") && maxBy(rowsWithTime, "value").value;

  const margin = {
    top: isList ? 20 : 40,
    bottom: isMobile ? 40 : 20,
    right: isMobile ? 20 : 50,
    left: isMobile ? 60 : 110
  };

  const width = useMemo(() => {
    if (!isList && locations.length === 1 && !isMobile) {
      return 910;
    } else if (!isList && locations.length > 1 && !isMobile) {
      return 730;
    } else if (isList && !isMobile) {
      return 670;
    } else if (isMobile) {
      return document.documentElement.clientWidth;
    }
  }, [isList, isMobile, locations.length]);

  const chartWidth = useMemo(() => {
    if (!isList && locations.length === 1) {
      return width - margin.left - margin.right;
    } else if (!isList && locations.length > 1) {
      return width - margin.left - margin.right;
    } else {
      return width - margin.left - margin.right;
    }
  }, [isList, locations.length, margin.left, margin.right, width]);
  const chartHeight = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleTime({
    range: [0, chartWidth],
    domain: extent(rowsWithTime, x)
  });

  const yScale = scaleLinear({
    range: [chartHeight, 0],
    domain: extent(rowsWithTime, y)
    // domain: [0, max(fullSerie, y)]
  });

  const mediumValue =
    minValue && maxValue && minValue + (maxValue - minValue) / 2;

  const maxDate = useMemo(() => {
    return maxBy(rowsWithTime, "time").time;
  }, [rowsWithTime]);

  const lastDateRows = useMemo(() => {
    return orderBy(
      rowsWithTime.filter(item => item.time >= maxDate && item.value),
      ["value", "location"],
      [direction === 0 ? "asc" : "desc"]
    );
  }, [direction, maxDate, rowsWithTime]);

  const minCounty = minBy(lastDateRows, "value").location;
  const maxCounty = maxBy(lastDateRows, "value").location;

  const lastDatumToShow = useMemo(() => {
    if (uomLabel && uom) {
      return `${numberFormat(lastDateRows[0].value)} ${uom} ${uomLabel}`;
    } else if (uom) {
      return `${numberFormat(lastDateRows[0].value)} ${uom}`;
    } else {
      return `${numberFormat(lastDateRows[0].value)}`;
    }
  }, [uom, lastDateRows, uomLabel]);

  const valueForCounty = useMemo(() => {
    if (!currentCounty) {
      return null;
    }

    const rowDataCounty = lastDateRows.filter(
      d => d.location === currentCounty
    );
    if (rowDataCounty.length > 0) {
      if (uomLabel && uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom} ${uomLabel}`;
      } else if (uom) {
        return `${numberFormat(rowDataCounty[0].value)} ${uom}`;
      } else {
        return `${numberFormat(rowDataCounty[0].value)}`;
      }
    } else {
      return `Not available`;
    }
  }, [currentCounty, lastDateRows, uom, uomLabel]);

  useEffect(() => {
    if (!isList && geoLevel === "US" && locations.length > 1) {
      setCurrentCounty("Texas");
    }
  }, [geoLevel, isList, locations.length, setCurrentCounty]);

  const topColor = "#00a9c5";
  const bottomColor = isMobile ? "#FFCA08" : "#3a4a9f";

  const [currentX, setCurrentX] = useState(null);

  return (
    <div>
      <div
        className={classNames("pt-2", "last-data", {
          "text-white": isMobile,
          "text-center": isMobile,
          "text-left": !isMobile,
          "text-pinkish": currentCounty
        })}
      >
        {currentCounty ? (
          <span>
            {currentCounty}:{" "}
            <span className="last-datum-value">{valueForCounty}</span>
          </span>
        ) : (
          <span>
            {direction === 0 && minCounty
              ? minCounty + ": "
              : maxCounty && maxCounty + ": "}
            <span className="last-datum-value">{lastDatumToShow}</span>
          </span>
        )}
      </div>
      <div
        className={classNames({
          "d-flex": !isMobile,
          "justify-content-between": !isMobile
        })}
      >
        <svg
          width={width}
          height={height}
          style={{ backgroundColor: isMobile ? denimBlue : white }}
        >
          <Grid
            top={margin.top}
            left={margin.left}
            xScale={xScale}
            yScale={yScale}
            stroke={isMobile ? white : "rgb(180,180,180,0.6)"}
            width={chartWidth}
            height={chartHeight}
            strokeWidth={0.4}
            numTicksRows={2}
            rowTickValues={[minValue, mediumValue, maxValue]}
            numTicksColumns={0}
          />
          {xAxis && !isMobile && (
            <AxisTop
              top={margin.top}
              left={margin.left}
              scale={xScale}
              numTicks={0}
              label={xAxis}
              stroke={isMobile ? white : "#b4b4b4"}
              strokeWidth={0.4}
              labelOffset={20}
              labelClassName={"y-axis"}
              tickStroke={"none"}
            />
          )}
          <AxisLeft
            scale={yScale}
            top={margin.top}
            left={margin.left}
            stroke={"none"}
            numTicks={3}
            label={yAxis && !isMobile ? yAxis : ""}
            labelOffset={52}
            labelClassName={"y-axis"}
            tickFormat={tickValue => {
              return uom
                ? `${numberFormat(tickValue)} ${"\n"}${uom}`
                : numberFormat(tickValue);
            }}
            tickValues={[minValue, mediumValue, maxValue]}
            tickStroke={"none"}
            tickLabelProps={(value, index) => ({
              fill: isMobile ? white : black,
              fontSize: 12,
              textAnchor: "end",
              fontFamily: "Montserrat"
            })}
          />
          <AxisBottom
            top={height - margin.bottom}
            left={margin.left}
            right={margin.right}
            scale={xScale}
            hideZero={false}
            strokeWidth={0.4}
            numTicks={isMobile || frequency === "M" ? 4 : undefined}
            tickStroke={isMobile ? white : "#b4b4b4"}
            stroke={isMobile ? white : "#b4b4b4"}
            tickFormat={v => moment(v).format(timeFormat)}
            tickLabelProps={(value, index) => ({
              fill: isMobile ? white : black,
              fontSize: 12,
              textAnchor: "middle",
              fontFamily: "Montserrat"
            })}
          />
          {dataByLocation &&
            locations &&
            locations.map((location, key) => (
              <Group key={key} top={margin.top} left={margin.left}>
                <LinePath
                  data={dataByLocation[location] && dataByLocation[location]}
                  x={d => xScale(x(d))}
                  y={d => yScale(y(d))}
                  stroke={locations.length > 1 ? lightBlue : pinkish}
                  strokeWidth={locations.length > 1 ? 2 : 3}
                  defined={d => d.value !== null}
                />
                <LinePath
                  data={dataByLocation[location] && dataByLocation[location]}
                  x={d => xScale(x(d))}
                  onClick={() => setCurrentCounty(location)}
                  y={d => yScale(y(d))}
                  stroke={"transparent"}
                  style={{ cursor: "pointer" }}
                  strokeWidth={4}
                  defined={d => d.value !== null}
                />
              </Group>
            ))}
          {minCounty && (
            <Group key={minCounty + "min"} top={margin.top} left={margin.left}>
              <LinePath
                data={dataByLocation[minCounty] && dataByLocation[minCounty]}
                x={d => xScale(x(d))}
                y={d => yScale(y(d))}
                stroke={direction === 0 ? topColor : bottomColor}
                strokeWidth={2}
                defined={d => d.value !== null}
              />
            </Group>
          )}
          {maxCounty && (
            <Group key={maxCounty + "max"} top={margin.top} left={margin.left}>
              <LinePath
                data={dataByLocation[maxCounty] && dataByLocation[maxCounty]}
                x={d => xScale(x(d))}
                y={d => yScale(y(d))}
                stroke={
                  locations.length > 1
                    ? direction === 0
                      ? bottomColor
                      : topColor
                    : pinkish
                }
                strokeWidth={locations.length > 1 ? 2 : 3}
                defined={d => d.value !== null}
              />
            </Group>
          )}
          {currentCounty && (
            <Group
              key={currentCounty + "current"}
              top={margin.top}
              left={margin.left}
            >
              <LinePath
                data={
                  dataByLocation[currentCounty] && dataByLocation[currentCounty]
                }
                x={d => xScale(x(d))}
                y={d => yScale(y(d))}
                stroke={pinkish}
                strokeWidth={4}
                defined={d => d.value !== null}
              />
            </Group>
          )}
          {currentCounty &&
            dataByLocation[currentCounty] &&
            !isList &&
            dataByLocation[currentCounty].map((d, i) => {
              const cx = xScale(x(d));
              const cy = yScale(y(d));
              return (
                <Group
                  top={margin.top}
                  left={margin.left}
                  key={`line-point-${i}`}
                >
                  <GlyphDot
                    cx={+cx}
                    cy={+cy}
                    r={currentX ? 5 : 10}
                    style={{ cursor: "pointer" }}
                    fill={currentX ? white : "transparent"}
                    strokeWidth={3}
                    stroke={currentX ? pinkish : "transparent"}
                    onMouseEnter={() => {
                      setCurrentX({ position: x(d), value: d });
                    }}
                    onMouseLeave={() => {
                      setCurrentX(null);
                    }}
                  />
                </Group>
              );
            })}

          {currentX && (
            <Group top={margin.top} left={margin.left}>
              <line
                x1={xScale(currentX.position)}
                x2={xScale(currentX.position)}
                y1={5}
                y2={chartHeight}
                stroke={isMobile ? white : black}
                strokeDasharray={4}
              ></line>
              <Text
                x={xScale(currentX.position) - 20}
                y={-10}
                stroke={"#f26852"}
                fontFamily={"Montserrat"}
              >
                {numberFormat(currentX.value.value)}
              </Text>
            </Group>
          )}
        </svg>
        {!isList && (
          <TableLastYear
            data={lastDateRows}
            uom={uom}
            tableLabel={tableLabel}
            isMobile={isMobile}
            setCurrentCounty={setCurrentCounty}
            currentCounty={currentCounty}
            geoLevel={geoLevel}
          />
        )}
      </div>
    </div>
  );
}
