import React, { useState, useContext, useMemo, useEffect } from "react";
import flatMap from "lodash/flatMap";
import MenuTop from "../../components/Desktop/MenuTop";
import classNames from "classnames";
import TopicIndicatorsMenuLeft from "../../components/Desktop/TopicIndicatorsMenuLeft";
import TopicIndicatorsList from "../../components/Desktop/TopicIndicatorsList";
// import IndicatorChoroplet from "../../components/Desktop/IndicatorChartDetail/IndicatorChoroplet";
import Landing from "../../components/Desktop/Landing";
import { ConfigContext } from "../../providers/ConfigProvider";
import CompareCountyState from "../../components/Desktop/CompareCountyState";
import { useIndicator } from "../../utils";

export default function Home({ match, location, history }) {
  let topic = match.params.topic || null;
  if (topic === null) {
    topic = 2;
  }

  const [currentCounty, setCurrentCounty] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const goal = match.params.goal || null;

  const indicatorsConfig = useContext(ConfigContext);
  const indicatorsByTopic =
    indicatorsConfig && indicatorsConfig.indicatorsByTopic;
  const indicatorsById = indicatorsConfig && indicatorsConfig.indicatorsById;

  const topicSelected =
    indicatorsConfig &&
    indicatorsConfig.topics.filter(
      t => parseInt(t.number) === parseInt(topic)
    )[0];

  const flatIndicators = useMemo(() => {
    if (indicatorsByTopic && topicSelected) {
      const indicators = indicatorsByTopic[topicSelected.number];
      return flatMap(topicSelected.subtopics, topic => indicators[topic]);
    }
    return [];
  }, [indicatorsByTopic, topicSelected]);

  const [
    selectedIndicators,
    indicator,
    setIndicator,
    clearIndicator
  ] = useIndicator(flatIndicators);

  useEffect(() => {
    if (window.NO_LANDING) {
      window.scrollTo(0, document.documentElement.clientHeight - 56);
    }
  }, []);

  useMemo(() => {
    window.scrollTo(0, document.documentElement.clientHeight - 56);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  return (
    <div>
      <Landing />
      <div id="Home" className="Home">
        {indicatorsConfig && (
          <>
            <div
              style={{ backgroundColor: "#FFF", zIndex: 9999 }}
              className={classNames("w-100", {
                "position-sticky": true,
                "top-0": true
              })}
              id="menu-filters"
            >
              <MenuTop
                topicSelected={topic}
                setIndicator={setIndicator}
                topics={indicatorsConfig.topics}
                goal={goal}
                history={history}
              />
              <div className="text-center border-top pt-3 pb-2">
                <CompareCountyState
                  currentCounty={currentCounty}
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                  setCurrentCounty={setCurrentCounty}
                />
              </div>
            </div>
            <div className="row container-fluid" id="bodyIndicators">
              <div className="col-md-3">
                <TopicIndicatorsMenuLeft
                  topic={indicatorsByTopic[topic]}
                  indicatorsById={indicatorsById}
                  topicSelected={topicSelected}
                  setIndicator={setIndicator}
                  indicatorSelected={indicator}
                />
              </div>
              <div className="col-md-9">
                <TopicIndicatorsList
                  topic={indicatorsByTopic[topic]}
                  indicatorsById={indicatorsById}
                  topicSelected={topicSelected}
                  currentCounty={currentCounty}
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                  indicatorSelected={indicator}
                  setIndicator={setIndicator}
                  clearIndicator={clearIndicator}
                  allSelectedIndicators={selectedIndicators}
                  setCurrentCounty={setCurrentCounty}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
