import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import countiesJson from "../../dataset/texas-counties.json";
import statesJson from "../../dataset/us-states.json";
import orderBy from "lodash/orderBy";
import classNames from "classnames";

export default function CompareCounty({
  currentCounty,
  currentState,
  setCurrentState,
  setCurrentCounty,
  type = "CNT",
  isMobile = false,
  isList = false
}) {
  const [countiesOpen, setOpenCounties] = useState(false);
  const toggleCounties = () => setOpenCounties(!countiesOpen);

  const [statesOpen, setOpenStates] = useState(false);
  const toggleStates = () => setOpenStates(!statesOpen);

  const counties = orderBy(
    countiesJson.features.map(county => {
      return county.properties.COUNTY;
    }, [])
  );

  const states = orderBy(
    statesJson.map(state => {
      return state.name;
    }, [])
  );

  return (
    <div
      className={classNames({
        "d-flex align-items-center justify-content-center": !isMobile,
        "mt-2": isMobile
      })}
    >
      <ButtonDropdown
        caret={"false"}
        direction={"down"}
        isOpen={countiesOpen}
        toggle={toggleCounties}
      >
        <DropdownToggle
          className={classNames("compare-county", "mb-2", {
            "compare-county-white": currentCounty
          })}
        >
          {currentCounty && counties.includes(currentCounty) ? (
            <span className="mr-2 county-selected">
              {currentCounty}
              <span
                onClick={e => {
                  setCurrentCounty(null);
                  setOpenCounties(false);
                }}
                className="ml-2 pointer"
              >
                <img
                  src={"/close-bold-pinkish.svg"}
                  alt={"Remove filter county"}
                />
              </span>
            </span>
          ) : (
            <span className="mr-2">
              <img src={"/combined-shape.svg"} className="mr-2" alt="Plus" />{" "}
              Compare with a {type === "CNT" ? "county" : "state"}
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu
          className="compare-county-list"
          style={{ height: 400, overflowY: "scroll" }}
        >
          {counties && (
            <>
              {counties.map(county => (
                <DropdownItem
                  className="compare-county-item"
                  onClick={() => setCurrentCounty(county)}
                  key={county}
                >
                  {county}
                </DropdownItem>
              ))}
            </>
          )}
        </DropdownMenu>
      </ButtonDropdown>

      <ButtonDropdown
        caret={"false"}
        direction={"down"}
        className={classNames({ "ml-2": !isMobile })}
        isOpen={statesOpen}
        toggle={toggleStates}
      >
        <DropdownToggle
          className={classNames("compare-county", "mb-2", {
            "compare-county-white": currentState
          })}
        >
          {currentState && states.includes(currentState) ? (
            <span className="mr-2 county-selected">
              {currentState}
              <span
                onClick={e => {
                  setCurrentState(null);
                }}
                className="ml-2 pointer"
              >
                <img
                  src={"/close-bold-pinkish.svg"}
                  alt={"Remove filter county"}
                />
              </span>
            </span>
          ) : (
            <span className="mr-2">
              <img src={"/combined-shape.svg"} className="mr-2" alt="Plus" />{" "}
              Compare with a state
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu
          className="compare-county-list"
          style={{ height: 400, overflowY: "scroll" }}
        >
          {states && (
            <>
              {states.map(state => (
                <DropdownItem
                  className="compare-county-item"
                  onClick={() => setCurrentState(state)}
                  key={state}
                >
                  {state}
                </DropdownItem>
              ))}
            </>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
}
