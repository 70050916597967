import React, { useState } from "react";
import classNames from "classnames";

export default function SplitsMobile({ chartsData, setCurrentChart, name }) {
  const [indexChart, setIndexChart] = useState(0);
  const numCharts = chartsData.length;
  return (
    <div className="pt-2">
      <div className="tabs-charts-mobile d-flex justify-content-between container">
        <div>
          {chartsData[indexChart - 1] ? (
            <img
              onClick={() => {
                setCurrentChart(chartsData[indexChart - 1]);
                setIndexChart(indexChart - 1);
              }}
              src={"/arrow-left-white.svg"}
              alt="Left"
            />
          ) : (
            <img
              onClick={() => {
                setCurrentChart(chartsData[numCharts - 1]);
                setIndexChart(numCharts - 1);
              }}
              src={"/arrow-left-white.svg"}
              alt="Left"
            />
          )}
        </div>
        <div className="d-flex text-center pt-2">
          {chartsData &&
            chartsData.map((chart, key) => (
              <div
                key={key}
                onClick={() => {
                  setCurrentChart(chart);
                  setIndexChart(key);
                }}
                className={classNames("dot", "mr-2", "pointer", {
                  "dot-active": chart.name === name
                })}
              ></div>
            ))}
        </div>
        <div>
          {chartsData[indexChart + 1] ? (
            <img
              onClick={() => {
                setCurrentChart(chartsData[indexChart + 1]);
                setIndexChart(indexChart + 1);
              }}
              src={"/arrow-right-white.svg"}
              alt="Right"
            />
          ) : (
            <img
              onClick={() => {
                setCurrentChart(chartsData[0]);
                setIndexChart(0);
              }}
              src={"/arrow-right-white.svg"}
              alt="Right"
            />
          )}
        </div>
      </div>
      <div className="p-2 mt-2 chart-name text-center">{name}</div>
    </div>
  );
}
