import React from "react";
import { Link } from "react-router-dom";
import "./IndicatorLinkBox.scss";

export default function IndicatorLinkBox({ title, idIndicator }) {
  return (
    <div className="IndicatorLinkBox p-3 mt-4">
      <div className="indicator-title">{title}</div>
      <div className="indicator-link pt-3">
        <Link to={`/indicator/${idIndicator}`}>
          LEARN MORE ABOUT THIS INDICATOR
        </Link>
      </div>
    </div>
  );
}
