import React from "react";
import classNames from "classnames";

export default function NoDataBlock({ isMobile, height }) {
  return (
    <div
      style={{ height: height ? height : 420 }}
      className={classNames(
        "d-flex",
        "justify-content-center",
        "align-items-center",
        {
          "bg-very-light-pink": !isMobile,
          "bg-denim-blue": isMobile,
          "text-white": isMobile
        }
      )}
    >
      Data is currently unavailable for this query
    </div>
  );
}
