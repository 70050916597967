import React from "react";
import classNames from "classnames";
import "./Landing.scss";

export default function Landing() {
  return (
    <div className={classNames("LandingDesktop")}>
      <div
        className="position-absolute"
        style={{ top: 20, right: 20, width: 64 }}
      >
        <img src={"/logo.svg"} alt="Logo Texas" />
      </div>
      <div className="position-absolute box-blue">
        <div className="pl-5 pr-5">
          <div className="row">
            <div className="col-md-6">
              <div className="pt-4 title-box border-bottom">
                SHAPING OUR FUTURE
              </div>
              <div className="pt-2 subtitle">
                EXPLORE THE DATA BEHIND THE TEXAS 2036 STRATEGIC FRAMEWORK
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5 introduction">
              <div className="pt-4">
                <div className="pt-2">
                  This site allows you to explore the data behind each indicator
                  included in{" "}
                  <b>
                    "Shaping Our Future - A Strategic Framework for the Future
                    of Texas".
                  </b>
                  The framework identifies 36 aspirational goals that can help
                  guide Texas towards ensuring it is the best place to live and
                  work.
                  <div className="mt-3">
                    The goals are measured by multiple indicators that reveal
                    the performance of Texas and its 254 counties.
                  </div>
                </div>
                <div className="mt-3">
                  To start exploring the indicators, select a policy area
                  below...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
