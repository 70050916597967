import React, { useState, useContext, useMemo } from "react";
import flatMap from "lodash/flatMap";
import classNames from "classnames";
import MenuTop from "../../components/Mobile/MenuTop";
import TopicIndicatorsList from "../../components/Mobile/TopicIndicatorsList";
import SelectorDetail from "../../components/Mobile/SelectorDetail";
import { ConfigContext } from "../../providers/ConfigProvider";
import Landing from "../../components/Mobile/Landing";
import ScrollToTop from "react-scroll-up";
import CompareCountyState from "../../components/Desktop/CompareCountyState";
import { useIndicator } from "../../utils";

export default function HomeMobile({ match }) {
  let topic = match.params.topic || null;
  if (topic === null) {
    topic = 2;
  }

  const [currentCounty, setCurrentCounty] = useState(null);
  const [currentState, setCurrentState] = useState(null);

  const indicatorsConfig = useContext(ConfigContext);
  const indicatorsByTopic =
    indicatorsConfig && indicatorsConfig.indicatorsByTopic;
  const indicatorsById = indicatorsConfig && indicatorsConfig.indicatorsById;

  const topicSelected =
    indicatorsConfig &&
    indicatorsConfig.topics.filter(
      t => parseInt(t.number) === parseInt(topic)
    )[0];

  const flatIndicators = useMemo(() => {
    if (indicatorsByTopic && topicSelected) {
      const indicators = indicatorsByTopic[topicSelected.number];
      return flatMap(topicSelected.subtopics, topic => indicators[topic]);
    }
    return [];
  }, [indicatorsByTopic, topicSelected]);

  const [
    selectedIndicators,
    indicator,
    setIndicator,
    clearIndicator
  ] = useIndicator(flatIndicators);

  useMemo(() => {
    window.scrollTo(0, document.documentElement.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  return (
    <div>
      <Landing />
      <div className="HomeMobile position-relative">
        {indicatorsConfig && (
          <>
            <div
              style={{ height: 185, zIndex: 1000 }}
              className={classNames("w-100", "bg-white", {
                "position-sticky": true,
                "top-0": true,
                "margin-fixed": true
              })}
              id="blockFilters"
            >
              <MenuTop
                topicSelected={topic}
                setIndicator={setIndicator}
                topics={indicatorsConfig.topics}
              />
              <div className="text-center border-top">
                <CompareCountyState
                  currentCounty={currentCounty}
                  currentState={currentState}
                  isMobile
                  setCurrentState={setCurrentState}
                  setCurrentCounty={setCurrentCounty}
                />
              </div>
              <SelectorDetail
                topic={topic}
                indicatorSelected={indicator}
                setIndicator={setIndicator}
                indicatorsById={indicatorsById}
                indicators={indicatorsConfig.rawData.filter(
                  t => t["topic_number"] === parseInt(topic)
                )}
              />
            </div>
            <div id="bodyIndicators">
              <TopicIndicatorsList
                topic={indicatorsByTopic[topic]}
                indicatorsById={indicatorsById}
                topicSelected={topicSelected}
                indicatorSelected={indicator}
                setCurrentCounty={setCurrentCounty}
                currentCounty={currentCounty}
                setIndicator={setIndicator}
                clearIndicator={clearIndicator}
                allSelectedIndicators={selectedIndicators}
              />
            </div>
          </>
        )}
        <ScrollToTop showUnder={160}>
          <span className="scroll-up-button">
            <img src={"/up.svg"} alt="Up" />
          </span>
        </ScrollToTop>
      </div>
    </div>
  );
}
