import React, { useContext, useMemo, useState, useEffect } from "react";
import { ConfigContext } from "../../providers/ConfigProvider";
import Indicator from "../Indicator";
import IndicatorMobile from "../IndicatorMobile";
import { MobileContext } from "../../providers/MobileProvider";
import get from "lodash/get";
import findIndex from "lodash/findIndex";

export default function IndicatorSwitch({ match, history }) {
  const indicatorsConfig = useContext(ConfigContext);
  const isMobile = useContext(MobileContext);
  const indicatorId = match.params.indicator;

  const indicatorData = useMemo(() => {
    if (!indicatorsConfig) {
      return {};
    }

    const indicatorsOrdered = indicatorsConfig.indicatorsOrdered;
    const indexIndicator = findIndex(indicatorsOrdered, [
      "id",
      parseInt(indicatorId)
    ]);

    const indicator = indicatorsOrdered.filter(
      i => parseInt(i.id) === parseInt(indicatorId)
    );

    return {
      indicator: indicator[0],
      nextIndicator: indicatorsOrdered[indexIndicator + 1]
        ? indicatorsOrdered[indexIndicator + 1]
        : null,
      prevIndicator: indicatorsOrdered[indexIndicator - 1]
        ? indicatorsOrdered[indexIndicator - 1]
        : null
    };
  }, [indicatorId, indicatorsConfig]);

  const { indicator, nextIndicator, prevIndicator } = indicatorData;

  const indicatorsByTab = indicatorsConfig && indicatorsConfig.indicatorsByTab;

  const chartsData = useMemo(() => {
    let charts = [];
    if (!indicatorData.indicator) {
      return charts;
    }

    if (indicatorData.indicator["data_url"]) {
      charts.push({
        name: indicatorData.indicator[`chart_title`],
        query: indicatorData.indicator[`data_url`],
        column: "data_url",
        geoLevel: indicatorData.indicator[`geo_level`],
        visualModel: indicatorData.indicator[`chart`],
        direction: indicatorData.indicator[`direction`],
        calc: indicatorData.indicator[`calc`],
        idIndicator: indicatorData.indicator[`id`],
        uom: indicatorData.indicator[`uom`],
        uomLabel: indicatorData.indicator[`uom_label`],
        xAxis: indicatorData.indicator[`x_axis_label`],
        yAxis: indicatorData.indicator[`y_axis_label`],
        hideLegend: indicatorData.indicator[`hide_legend`],
        indicatorTab: indicatorData.indicator[`chart_group`],
        tableLabel: indicatorData.indicator[`table_label`]
      });
    }

    return charts;
  }, [indicatorData]);

  const topics = indicatorsConfig && indicatorsConfig.topics;

  const [currentChart, setCurrentChart] = useState(null);

  useEffect(() => {
    setCurrentChart(get(chartsData, "[0]"));
  }, [chartsData]);

  return (
    <div>
      {isMobile ? (
        <IndicatorMobile
          topics={topics}
          chartsData={chartsData}
          currentChart={currentChart}
          setCurrentChart={setCurrentChart}
          nextIndicator={nextIndicator}
          prevIndicator={prevIndicator}
          history={history}
          indicator={indicator}
        />
      ) : (
        <Indicator
          chartsData={chartsData}
          currentChart={currentChart}
          setCurrentChart={setCurrentChart}
          nextIndicator={nextIndicator}
          prevIndicator={prevIndicator}
          history={history}
          indicator={indicator}
          indicatorsByTab={indicatorsByTab}
        />
      )}
    </div>
  );
}
