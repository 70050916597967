import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import orderBy from "lodash/orderBy";
import classNames from "classnames";
import { ConfigContext } from "../../../providers/ConfigProvider";

export default function SplitsDesktop({ chartsData, name, tab, idIndicator }) {
  const indicatorsConfig = useContext(ConfigContext);
  const indicatorsByTab = indicatorsConfig && indicatorsConfig.indicatorsByTab;

  const tabs = useMemo(() => {
    if (indicatorsByTab && indicatorsByTab[tab]) {
      return orderBy(indicatorsByTab[tab], "chart_title", "asc");
    }
  }, [indicatorsByTab, tab]);

  return (
    <div className="tabs-charts d-flex mt-4">
      {tabs &&
        tabs.map((chart, key) => (
          <Link
            to={`/indicator/${chart.id}`}
            key={key}
            className={classNames("tab-item pl-3 pr-3 pointer", {
              "tab-selected": chart.id === idIndicator
            })}
          >
            {chart["chart_title"] || chart["title"]}
          </Link>
        ))}
    </div>
  );
}
