import React from "react";

import { MobileProvider } from "./providers/MobileProvider";
import AppContainer from "./components/AppContainer";

function App() {
  return (
    <MobileProvider>
      <AppContainer />
    </MobileProvider>
  );
}

export default App;
