import React from "react";
import { Link } from "react-router-dom";
import "./IndicatorLinkBox.scss";

export default function IndicatorLinkBox({ title, idIndicator }) {
  return (
    <div className="IndicatorLinkBoxMobile w-70 p-3 mt-4">
      <div className="indicator-title">{title}</div>
      <div className="indicator-link pt-3">
        <Link
          to={`/indicator/${idIndicator}`}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          GO TO INDICATOR PAGE ↗
        </Link>
      </div>
    </div>
  );
}
