import React from "react";
import "./GoalBox.scss";

export default function GoalBox({ goal, number, link }) {
  return (
    <div className="GoalBoxMobile p-2 mt-2 w-70">
      <div className="goal-number">Framework Goal {number}</div>
      <div className="goal-title pt-1">{goal}</div>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="goal-link pt-2"
      >
        Learn More About This Goal
      </a>
    </div>
  );
}
