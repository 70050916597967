import { useState, useCallback, useMemo } from "react";
import omit from "lodash/omit";

export function useIndicator(flatIndicators) {
  const [selectedIndicators, setIndicators] = useState({});

  const setIndicator = useCallback(indicator => {
    setIndicators(indicators => ({
      ...indicators,
      [indicator]: true
    }));
  }, []);

  const clearIndicator = useCallback(indicator => {
    setIndicators(indicators => omit(indicators, indicator));
  }, []);

  const indicator = useMemo(() => {
    const activeIndicators = flatIndicators
      .filter(i => selectedIndicators[i])
      .reverse();
    return activeIndicators.length > 0 ? activeIndicators[0] : null;
  }, [flatIndicators, selectedIndicators]);

  return [selectedIndicators, indicator, setIndicator, clearIndicator];
}

export function makeChartData(indicatorData) {
  let charts = [];

  if (!indicatorData) {
    return undefined;
  }

  if (indicatorData["data_url"]) {
    charts.push({
      name: indicatorData[`chart_title`],
      query: indicatorData[`data_url`],
      column: "data_url",
      geoLevel: indicatorData["geo_level"],
      visualModel: indicatorData[`chart`],
      calc: indicatorData[`calc`],
      direction: indicatorData[`direction`],
      idIndicator: indicatorData[`id`],
      uom: indicatorData[`uom`],
      uomLabel: indicatorData[`uom_label`],
      xAxis: indicatorData[`x_axis_label`],
      yAxis: indicatorData[`y_axis_label`],
      hideLegend: indicatorData[`hide_legend`],
      indicatorTab: indicatorData[`chart_group`],
      tableLabel: indicatorData[`table_label`]
    });
  }

  if (!charts.length) {
    return undefined;
  }

  return charts;
}

export function serieToDeltas(serie) {
  serie.reduce((out, item, i) => {
    const time = serie[i].time;

    if (i === 0) {
      return [{ value: 0, time }];
    }

    if (serie[i].value === null || serie[i - 1].value === null) {
      out.push({ value: null, time });
    } else {
      out.push({
        value:
          ((serie[i].value - serie[i - 1].value) / serie[i - 1].value) * 100.0,
        time
      });
    }

    return out;
  }, []);
}

export function numberFormat(number) {
  return number.toLocaleString("en", {
    maximumFractionDigits: 2
  });
}
