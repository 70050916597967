import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ScrollToTop from "react-scroll-up";
import IndicatorPerformanceTrend from "../../components/Desktop/IndicatorPerformanceTrend";
import ExternalLinkBox from "../../components/Mobile/ExternalLinkBox";
import GoalBox from "../../components/Mobile/GoalBox";
import IndicatorChartDetail from "../../components/Desktop/IndicatorChartDetail";
import NoDataBlock from "../../components/Desktop/IndicatorChartDetail/NoDataBlock";
import LegendCharts from "../../components/Desktop/LegendCharts";
import CompareCounty from "../../components/Desktop/CompareCounty";
import "./Indicator.scss";
import { FacebookShareButton, TwitterShareButton } from "react-share";

export default function IndicatorMobile({
  match,
  history,
  topics,
  indicator,
  currentChart,
  nextIndicator,
  prevIndicator,
  setCurrentChart,
  chartsData
}) {
  const [currentCounty, setCurrentCounty] = useState(null);

  return (
    <div className="IndicatorMobile">
      {indicator && (
        <>
          <div className="top-indicator pt-2 pb-2">
            <div className="pl-3 pr-3">
              <Link to={"/"}>
                <img width={48} src={"/logo-mobile-colors.svg"} alt="Texas" />
              </Link>
            </div>
          </div>
          <div className="intestation-indicator container pt-3 pb-3">
            <div className="topic-name">{indicator["topic"]}</div>
            <div className="pt-2 indicator-title">{indicator["title"]}</div>
          </div>
          <div className="text-center">
            {indicator["geo_level"] && indicator["geo_level"] === "CNT" && (
              <CompareCounty
                currentCounty={currentCounty}
                setCurrentCounty={setCurrentCounty}
                type={"CNT"}
              />
            )}
            {indicator["geo_level"] && indicator["geo_level"] === "US" && (
              <CompareCounty
                currentCounty={currentCounty}
                setCurrentCounty={setCurrentCounty}
                type={"US"}
              />
            )}
          </div>
          <div>
            {currentChart && currentChart["visualModel"] ? (
              <IndicatorChartDetail
                {...currentChart}
                chartsData={chartsData}
                width={document.documentElement.clientWidth}
                setCurrentCounty={setCurrentCounty}
                isMobile={true}
                currentCounty={currentCounty}
                setCurrentChart={setCurrentChart}
              />
            ) : (
              <NoDataBlock isMobile={true} />
            )}
          </div>
          <div className="indicator-body pl-4 pr-4 pb-4 pt-4">
            <LegendCharts
              geoLevel={indicator["geo_level"]}
              visualModel={indicator["chart"]}
            />
            <div className="share-block text-center pt-3 pb-3">
              <span className="label mr-2">Share:</span>
              <span className="social mr-2">
                <FacebookShareButton url={window.location.href}>
                  Facebook
                </FacebookShareButton>
              </span>
              <span className="social">
                <TwitterShareButton url={window.location.href}>
                  Twitter
                </TwitterShareButton>
              </span>
            </div>
            <div className="indicator-subtitle">{indicator["subtitle"]}</div>
            <div className="pt-3">
              <div className="trend-performance justify-content-between align-items-center d-flex pb-4">
                <div className="border-performance-trend"></div>
                <IndicatorPerformanceTrend
                  performance={indicator["performance"]}
                  trend={indicator["trend"]}
                />
                <div className="border-performance-trend"></div>
              </div>
              <div className="indicator-performance">
                The current performance is{" "}
                <span
                  className={classNames({
                    red: indicator["performance"] === "Red",
                    green: indicator["performance"] === "Green",
                    gray: indicator["performance"] === "",
                    yellow: indicator["performance"] === "Yellow"
                  })}
                >
                  {indicator["performance"] === ""
                    ? "Not yet tracked"
                    : indicator["performance"]}
                </span>
              </div>
              {indicator["type_indicator"] === 0 && (
                <div className="indicator-trend mt-4">
                  The current trend is{" "}
                  {indicator["trend"] ? (
                    <span
                      className={classNames({
                        red: indicator["performance"] === "Red",
                        green: indicator["performance"] === "Green",
                        gray: indicator["performance"] === "",
                        yellow: indicator["performance"] === "Yellow"
                      })}
                    >
                      {indicator["trend"]}
                    </span>
                  ) : (
                    <span>"Not yet tracked"</span>
                  )}
                </div>
              )}
              {indicator["description"] && (
                <div className="indicator-description mt-2">
                  {indicator["description"]}
                </div>
              )}
              {indicator["paragraph_goal"] && (
                <div className="indicator-paragraph mt-4 pt-2 pb-2">
                  {indicator["paragraph_goal"]}
                </div>
              )}
              {indicator["frequency"] && (
                <div className="mt-4 frequency">
                  <div className="label">Data update frequency</div>
                  <div className="frequency-value mt-2">
                    {indicator["frequency"] === "A" ? "Annual" : "Monthly"}
                  </div>
                </div>
              )}
              {indicator["source"] && (
                <div className="mt-4 source">
                  <div className="label">Source Data</div>
                  <div className="source-value mt-2">
                    <a
                      rel="noopener noreferrer"
                      href={indicator["source_link"]}
                      target="_blank"
                    >
                      {indicator["source"]}
                    </a>
                  </div>
                </div>
              )}

              <div>
                {indicator && indicator["goal_external_link"] && (
                  <GoalBox
                    link={indicator["goal_external_link"]}
                    goal={indicator["goal"]}
                    number={indicator["goal_number"]}
                  />
                )}
                {indicator["external_link"] && (
                  <div className="mt-3">
                    <ExternalLinkBox link={indicator["external_link"]} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="navigation-indicators container pb-4">
            {prevIndicator && (
              <button
                onClick={() =>
                  history.push(`/indicator/${prevIndicator["id"]}`)
                }
                className="w-100 pt-2 pb-2"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <img
                    className=""
                    //style={{ left: 10, top: 10 }}
                    src={"/arrow.svg"}
                    alt="Left"
                  />
                  <div className="text-center">
                    <div className="mr-2 label">Previous indicator</div>
                    {prevIndicator["title"]}
                  </div>
                  <div></div>
                </div>
              </button>
            )}
            {nextIndicator && (
              <button
                onClick={() =>
                  history.push(`/indicator/${nextIndicator["id"]}`)
                }
                className="mt-2 w-100 pt-2 pb-2"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div></div>
                  <div className="text-center">
                    <div className="mr-2 label">Next indicator</div>
                    {nextIndicator["title"]}
                  </div>
                  <img
                    src={"/icon-arrow-right.png"}
                    alt="Right"
                    className="text-right flex-end"
                  />
                </div>
              </button>
            )}
          </div>
        </>
      )}
      <ScrollToTop showUnder={160}>
        <span className="scroll-up-button">
          <img src={"/up.svg"} alt="Up" />
        </span>
      </ScrollToTop>
    </div>
  );
}
