import React, { useState, useMemo, useEffect } from "react";
import IndicatorLineChart from "./IndicatorLineChart";
import IndicatorBarChart from "./IndicatorBarChart";
import IndicatorTopBottomUs from "./IndicatorTopBottomUs";
import IndicatorMapChart from "./IndicatorMapChart";
import IndicatorChoroplet from "./IndicatorChoroplet";
import IndicatorChoropletUSA from "./IndicatorChoropletUSA";
//import FilterYearMonth from "./FilterYearMonth"
import FilterYear from "./FilterYear";
import SplitsMobile from "./SplitsMobile";
import SplitsDesktop from "./SplitsDesktop";
import { Spinner } from "reactstrap";
import { useRunRj } from "react-rocketjump";
import { IndicatorDataState } from "./localstate";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import sumBy from "lodash/sumBy";
import meanBy from "lodash/meanBy";
import min from "lodash/min";
import max from "lodash/maxBy";
import moment from "moment";
import orderBy from "lodash/orderBy";
import classNames from "classnames";
import "./IndicatorChartDetail.scss";

const getTimeFormat = frequency => {
  if (frequency === "A") {
    return "YYYY";
  }
  if (frequency === "M") {
    return "YYYY-MM";
  }
  return undefined;
};

const HEIGHTS = {
  linechart: 450,
  barchart: 450,
  "top and bottom": 450,
  "texas map": 460,
  choroplet: 500,
  "choroplet usa": 500
};

export default function IndicatorChartDetail({
  visualModel,
  idIndicator,
  query,
  column,
  chartsData,
  isList = false,
  setCurrentChart,
  name,
  isMobile,
  setCurrentCounty,
  currentCounty,
  calc = "sum",
  direction,
  width,
  height,
  hidden,
  uom,
  uomLabel,
  xAxis,
  yAxis,
  hideLegend,
  geoLevel,
  indicatorTab
}) {
  const [{ data }] = useRunRj(IndicatorDataState, [idIndicator]);

  // DATE FILTERS
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    if (!isList) {
      setFromDate(null);
      setToDate(null);
      setCurrentCounty(null);
    }
  }, [idIndicator, isList, setCurrentCounty]);

  // const rows = get(data, "data", []);
  const rows = useMemo(() => {
    let out = get(data, "records", []);

    if (fromDate) {
      out = out.filter(x => moment(x.time).toDate() >= fromDate);
    }
    if (toDate) {
      out = out.filter(x => moment(x.time).toDate() <= toDate);
    }
    return out;
  }, [data, fromDate, toDate]);

  const dates = useMemo(() => {
    let dataArray = get(data, "records", []);
    return dataArray.map(x => moment(x.time).toDate());
  }, [data]);

  const aggregator = calc === "sum" ? sumBy : meanBy;

  const firstDatum = get(data, "frequency");
  let frequency = "A";
  if (firstDatum !== undefined) {
    frequency = firstDatum;
  }

  const format = getTimeFormat(frequency);

  const byTime = groupBy(rows, "time");
  let aggregateSerie = [];
  Object.keys(byTime).forEach(key => {
    let value;
    value = aggregator(byTime[key], "value");
    const time = moment(key).toDate();
    aggregateSerie.push({
      value,
      time
    });
  });
  aggregateSerie = orderBy(aggregateSerie, "time");

  let minValueTime = min(dates);
  const maxValueTime = max(dates);

  let countySerie = undefined;
  if (currentCounty) {
    countySerie = rows
      .filter(
        item => item.location === currentCounty || item.state === currentCounty
      )
      .map(item => ({
        value: item.value,
        time: moment(item.time).toDate()
      }));
  }
  countySerie = orderBy(countySerie, "time");

  const renderChartSwitch = (visualModel, hidden) => {
    const height = get(HEIGHTS, visualModel.toLowerCase(), 400);

    if (hidden) {
      return (
        <div className="bg-very-light-pink" style={{ height, width }}></div>
      );
    }

    switch (visualModel.toLowerCase()) {
      case "linechart":
        return (
          <IndicatorLineChart
            frequency={frequency}
            timeFormat={format}
            width={width}
            isMobile={isMobile}
            height={height}
            setCurrentCounty={setCurrentCounty}
            currentCounty={currentCounty}
            minValueTime={minValueTime}
            maxValueTime={maxValueTime}
            rows={rows}
            direction={direction}
            isList={isList}
            uom={uom}
            uomLabel={uomLabel}
            geoLevel={geoLevel}
          />
        );
      case "barchart":
        return (
          <IndicatorBarChart
            aggregateSerie={aggregateSerie}
            countySerie={countySerie}
            frequency={frequency}
            timeFormat={format}
            width={width}
            isMobile={isMobile}
            height={height}
            uom={uom}
            uomLabel={uomLabel}
            xAxis={xAxis}
            yAxis={yAxis}
          />
        );
      case "top and bottom":
        return (
          <IndicatorTopBottomUs
            frequency={frequency}
            timeFormat={format}
            aggregateSerie={aggregateSerie}
            countySerie={countySerie}
            setFromDate={setFromDate}
            width={width}
            setCurrentCounty={setCurrentCounty}
            currentCounty={currentCounty}
            minValueTime={minValueTime}
            maxValueTime={maxValueTime}
            rows={rows}
            direction={direction}
            isList={isList}
            isMobile={isMobile}
            height={height}
            uom={uom}
            uomLabel={uomLabel}
            geoLevel={geoLevel}
            xAxis={xAxis}
            yAxis={yAxis}
          />
        );
      case "texas map":
        return (
          <IndicatorMapChart
            aggregateSerie={aggregateSerie}
            countySerie={countySerie}
            frequency={frequency}
            timeFormat={format}
            setCurrentCounty={setCurrentCounty}
            width={width}
            currentCounty={currentCounty}
            isList={isList}
            isMobile={isMobile}
            height={height}
            geoLevel={geoLevel}
            rows={rows}
            uom={uom}
            uomLabel={uomLabel}
          />
        );
      case "choroplet":
        return (
          <IndicatorChoroplet
            aggregateSerie={aggregateSerie}
            countySerie={countySerie}
            frequency={frequency}
            timeFormat={format}
            direction={direction}
            setCurrentCounty={setCurrentCounty}
            width={width}
            currentCounty={currentCounty}
            isList={isList}
            isMobile={isMobile}
            height={height}
            geoLevel={geoLevel}
            rows={rows}
            uom={uom}
            uomLabel={uomLabel}
          />
        );
      case "choroplet usa":
        return (
          <IndicatorChoropletUSA
            aggregateSerie={aggregateSerie}
            countySerie={countySerie}
            frequency={frequency}
            timeFormat={format}
            setCurrentCounty={setCurrentCounty}
            width={width}
            currentCounty={currentCounty}
            isList={isList}
            direction={direction}
            isMobile={isMobile}
            height={height}
            geoLevel={geoLevel}
            rows={rows}
            uom={uom}
            uomLabel={uomLabel}
          />
        );
      default:
        return (
          <IndicatorLineChart
            frequency={frequency}
            timeFormat={format}
            width={width}
            isMobile={isMobile}
            height={height}
            setCurrentCounty={setCurrentCounty}
            currentCounty={currentCounty}
            minValueTime={minValueTime}
            maxValueTime={maxValueTime}
            rows={rows}
            direction={direction}
            isList={isList}
            uom={uom}
            uomLabel={uomLabel}
            geoLevel={geoLevel}
          />
        );
    }
  };
  return (
    <div
      className={classNames("IndicatorChartDetail", {
        "bg-denim-blue": isMobile,
        "bg-very-light-white": !isMobile
      })}
    >
      {indicatorTab && !isMobile && !isList && (
        <SplitsDesktop
          tab={indicatorTab}
          name={name}
          idIndicator={idIndicator}
          chartsData={chartsData}
        />
      )}
      {chartsData.length > 1 && isMobile && (
        <SplitsMobile
          setCurrentChart={setCurrentChart}
          name={name}
          chartsData={chartsData}
        />
      )}
      {!data ? (
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{ height: 420 }}
        >
          <Spinner color={"primary"} />
        </div>
      ) : (
        <div>{renderChartSwitch(visualModel, hidden)}</div>
      )}
      {setCurrentChart && query && (
        <FilterYear
          minValueTime={minValueTime}
          maxValueTime={maxValueTime}
          toDate={toDate}
          isMobile={isMobile}
          fromDate={fromDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      )}
    </div>
  );
}
