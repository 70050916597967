import React from "react";
import "./ExternalLinkBox.scss";

export default function ExternalLinkBox({ link }) {
  return (
    <div className="ExternalLinkBox p-2 mt-3">
      <div className="link-title">DISCOVER MORE</div>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="external-link pt-2"
      >
        VISIT THE TEXAS 2036 STRATEGIC FRAMEWORK
      </a>
    </div>
  );
}
